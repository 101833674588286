import { memo, useEffect, useState } from "react";
import { fetchDashboardStat } from "../../../redux/slices/dashboardSlice";
import { getCustomerId } from "../../../utils/SessionHelper";
import "./DashboardStats.scss";
import { useDispatch } from "react-redux";
import { ReactComponent as UpRedArrow } from "../../../assets/img/new-theme/up-red-arrow.svg";
import { ReactComponent as DownGreenArrow } from "../../../assets/img/new-theme/down-green-arrow.svg";
import { ReactComponent as Managed } from "../../../assets/img/new-theme/managed.svg";
import { ReactComponent as Shadow } from "../../../assets/img/new-theme/shadow.svg";
import { ReactComponent as Sensitive } from "../../../assets/img/new-theme/sensitive.svg";
import { ReactComponent as Accounts } from "../../../assets/img/new-theme/accounts.svg";
import { ReactComponent as Resources } from "../../../assets/img/new-theme/resources.svg";
import { ReactComponent as Users } from "../../../assets/img/new-theme/users.svg";
import { useHistory } from "react-router-dom";

const DashboardStats = ({ selectedAccount }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const goToClouds = () => {
    history.push("/cloud/assets");
  };

  const goToDatastores = () => {
    history.push("/datastore");
  };

  const [deltaValueBig, setDeltaValueBig] = useState(false);

  const [statsData, setStatsData] = useState([
    {
      id: "risk",
      title: "Risk",
      headerSubText: "",
      type: "highlighted",
      data: [
        {
          id: "stores",
          storesCount: 0,
          storesDelta: 0,
          arrowDirection: "",
          footerText: "Stores",
        },
        {
          id: "issues",
          storesCount: 0,
          storesDelta: 0,
          arrowDirection: "",
          footerText: "Issues",
        },
      ],
    },
    {
      id: "policy",
      title: "Policy",
      headerSubText: "",
      type: "highlighted",
      data: [
        {
          id: "stores",
          storesCount: 0,
          storesDelta: 0,
          arrowDirection: "",
          footerText: "Stores",
        },
        {
          id: "issues",
          storesCount: 0,
          storesDelta: 0,
          arrowDirection: "",
          footerText: "Compliance Violation",
        },
      ],
    },
    {
      id: "data",
      title: "Data",
      data: [
        {
          id: "managed",
          label: "Managed",
          value: 0,
          icon: <Managed />,
          delta: 0,
          arrowDirection: "",
          arrowColor: "normal",
        },
        {
          id: "shadow",
          label: "Shadow",
          value: 0,
          icon: <Shadow />,
          delta: 0,
          arrowDirection: "",
        },
        {
          id: "sensitive",
          label: "Sensitive",
          value: 0,
          icon: <Sensitive />,
          delta: 0,
          arrowDirection: "",
          arrowColor: "normal",
        },
      ],
      type: "sample",
      clickAction: goToDatastores,
    },
    {
      id: "cloud",
      title: "Cloud",
      data: [
        {
          id: "accounts",
          label: "Accounts",
          value: 0,
          icon: <Accounts />,
          delta: 0,
          arrowDirection: "",
          arrowColor: "normal",
        },
        {
          id: "resources",
          label: "Resources",
          value: 0,
          icon: <Resources />,
          delta: 0,
          arrowDirection: "",
          arrowColor: "normal",
        },
        {
          id: "users",
          label: "Users",
          value: 0,
          icon: <Users />,
          delta: 0,
          arrowDirection: "",
          arrowColor: "normal",
        },
      ],
      type: "sample",
      clickAction: goToClouds,
    },
  ]);

  const getDeltaClass = (dataItem) => {
    if (dataItem.arrowDirection === "up" && dataItem.arrowColor !== "normal") {
      return "store-count-delta-red";
    } else if (
      dataItem.arrowDirection === "down" &&
      dataItem.arrowColor !== "normal"
    ) {
      return "store-count-delta-green";
    } else {
      return "store-count-delta-normal";
    }
  };
  const getDataCards = (stat) => {
    if (stat) {
      return stat.data.map((dataItem, index) => (
        <div className="row mb-2" key={`stat${index}`}>
          <div className={`${deltaValueBig ? "col-6" : "col-6"} pr-0 d-flex`}>
            {dataItem.icon}{" "}
            <span className="data-card-header ml-2">{dataItem.label}</span>
          </div>
          <div className={`col-3 text-right data-card-value pr-1`}>
            {dataItem.value}{" "}
          </div>
          <div className={`${deltaValueBig ? "col-3" : "col-3"} px-0`}>
            {dataItem.arrowDirection === "up" && (
              <UpRedArrow
                width={"14px"}
                height={"14px"}
                className={
                  dataItem.arrowColor === "normal" ? "normal-delta-arrow" : ""
                }
              />
            )}
            {dataItem.arrowDirection === "down" && (
              <DownGreenArrow
                className={
                  dataItem.arrowColor === "normal" ? "normal-delta-arrow" : ""
                }
              />
            )}

            {dataItem.delta !== 0 && (
              <span className={`store-count-delta ${getDeltaClass(dataItem)}`}>
                {dataItem.delta && dataItem.delta > 0
                  ? dataItem.delta
                  : dataItem.delta * -1}
              </span>
            )}
          </div>
        </div>
      ));
    }
    return null;
  };

  const updateStats = (stats) => {
    const tempStatData = [...statsData];
    let deltaValueGreater = false;
    if (stats) {
      for (let index = 0; index < tempStatData.length; index++) {
        let statObj = tempStatData[index];
        if (statObj) {
          let dataObj = stats?.inventory[statObj.id];
          let deltaObj = stats?.delta7?.inventory[statObj.id];
          switch (statObj.id) {
            case "cloud":
            case "data":
              statObj.data.map((item) => {
                item.value = dataObj && dataObj[item.id] ? dataObj[item.id] : 0;
                item.delta =
                  deltaObj && deltaObj[item.id] ? deltaObj[item.id] : 0;
                item.arrowDirection =
                  item.delta > 0 ? "up" : item.delta < 0 ? "down" : "";
                if (item.delta > 999 && !deltaValueGreater) {
                  deltaValueGreater = true;
                }
              });
              break;

            case "risk":
            case "policy":
              statObj.data.map((item) => {
                item.storesCount =
                  dataObj && dataObj[item.id] ? dataObj[item.id] : 0;
                item.storesDelta =
                  deltaObj && deltaObj[item.id] ? deltaObj[item.id] : 0;
                item.arrowDirection =
                  item.storesDelta > 0
                    ? "up"
                    : item.storesDelta < 0
                    ? "down"
                    : "";
              });
              break;
            default:
              statObj.arrowDirection = "";
              break;
          }
        }
      }
    }
    setDeltaValueBig(deltaValueGreater);
    setStatsData([...tempStatData]);
  };

  const getDashboardStats = async (selectedAccount) => {
    const payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
    };
    let resp = await dispatch(fetchDashboardStat(payload));
    if (resp && resp.payload && !resp.payload.err) {
      updateStats(resp.payload);
    }
  };
  useEffect(() => {
    getDashboardStats(selectedAccount);
  }, [selectedAccount]);

  return (
    <div className="row dashboard-stats">
      {statsData.map((stat, index) => {
        return (
          <div
            className="col-md-12 col-lg-6 col-xl-3"
            key={`dash-stat${index}`}
          >
            <div
              className={`${
                stat.type === "highlighted" ? "highlighted-card" : "data-card"
              } ${stat?.clickAction ? "cursor-pointer" : ""}`}
              onClick={() => (stat?.clickAction ? stat?.clickAction() : null)}
            >
              <div className="card-contents">
                <div className="title">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex"> {stat.title}</div>
                    <div className="d-flex severity mt-1">
                      {stat.headerSubText}
                    </div>
                  </div>

                  <hr />
                </div>
                {stat.type === "highlighted" ? (
                  <>
                    <div className="d-flex highlighted-sub-card justify-content-between">
                      {stat?.data?.map((subStat, index) => {
                        return (
                          <div key={`highlighted-card-${index}`}>
                            <div className="d-flex align-items-center score-section">
                              <div className="store-count mr-2">
                                {" "}
                                {subStat.storesCount}
                              </div>
                              {subStat.arrowDirection === "up" && (
                                <UpRedArrow width={"20px"} height={"20px"} />
                              )}
                              {subStat.arrowDirection === "down" && (
                                <DownGreenArrow
                                  width={"20px"}
                                  height={"20px"}
                                />
                              )}
                              {subStat.storesDelta !== 0 && (
                                <div
                                  className={`store-count-delta ${
                                    subStat.arrowDirection === "up"
                                      ? "store-count-delta-red"
                                      : "store-count-delta-green"
                                  }`}
                                >
                                  {" "}
                                  {subStat.storesDelta > 0
                                    ? subStat.storesDelta
                                    : subStat.storesDelta * -1}
                                </div>
                              )}
                            </div>
                            <div className="stores mt-3">
                              {subStat.footerText}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  getDataCards(stat)
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default memo(DashboardStats);
