import { axiosPrivate } from "../../utils/ApiHandler";
import { getUserId } from "../../utils/SessionHelper";

export const fetchTickets = () => {
  const user_id = getUserId();
  return axiosPrivate.get(`/jira/getBulkTickets?userId=${user_id}&key=CLOUD`);
};

/**
 * Add the accounts with the specified account details.
 * @param {object} params -- payload params
 * @returns the account details added.
 */
export const addTicket = (params) => {
  return axiosPrivate.post(`/jira/ticket`, params);
};
