import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  fetchDataStoreTags,
  fetchDatastoreNames,
  fetchSensitiveInfo,
  updateDataStoreTags,
} from "../apis/dataStoreAPI";

const initialState = {
  tags: null,
};

// Async calls.
export const listDataStoreTagsAsync = createAsyncThunk(
  "dataStore/tags/get",
  async (params) => {
    const response = await fetchDataStoreTags(params);
    return response.data;
  }
);

export const listDataStoreNames = createAsyncThunk(
  "dataStore/names/get",
  async (params) => {
    const response = await fetchDatastoreNames(params);
    return response.data;
  }
);

export const updateDataStoreTagsAsync = createAsyncThunk(
  "dataStore/tags/set",
  async (params) => {
    const response = await updateDataStoreTags(params);
    return response.data;
  }
);

export const listSensitiveData = createAsyncThunk(
  "sensitive/info/get",
  async (params) => {
    const response = await fetchSensitiveInfo(params);
    return response.data;
  }
);

// Datastore slice for configuring reducers and actions.
export const dataStoreSlice = createSlice({
  name: "report",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
});

export default dataStoreSlice.reducer;

// Selectors will go here
