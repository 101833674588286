import React, { memo, useEffect, useState } from "react";
import { axiosDenaliPrivate } from "../../../utils/ApiHandler";
import {
  getCustomerId,
  getSelectedDataFlow,
} from "../../../utils/SessionHelper";
import {
  formatLine,
  getScoreColor,
  getScoreBorder,
} from "../../../utils/styles";
import "./BottomPanelComponent.scss";
import moment from "moment";
import { regions } from "../../maps/aws_regions";

import { ACCESS_SOURCES } from "./data";
// import { items } from "./data";
import { useHistory, useParams } from "react-router-dom";
import AttackIcon from "../../../assets/img/icons/attack-icon.svg";
import complianceViolation from "../../../assets/img/icons/compliance-violation.svg";
import Tabs from "../../tabs";

const BottomPanelComponent = ({
  RefreshChart,
  createTicket,
  setCreateTicket,
  attackPaths,
  setSelectedTab,
  selectedBar,
  setDatastoreSources,
  setIsDatastoreIssuesModal,
  datastoreSources,
  selectedTab,
  setSelectedNode,
  isUSOnlyAccess,
}) => {
  const [selected, setSelected] = useState(0);
  const history = useHistory();
  const params = useParams();
  const selectedDataFlow = getSelectedDataFlow();
  const datastoreId = params.id?.replaceAll("_", "/");
  const handleRefresh = (idx, name) => {
    setSelected(idx);
    RefreshChart(idx, name);
    setCreateTicket(false);
  };
  const [updatedDatastoreSources, setUpdatedDatastoreSources] = useState([]);

  const checkComplianceViolationOnLiveness = (source) => {
    return (
      source &&
      source.region !== "North America" &&
      !source.region?.toLowerCase()?.includes("us") &&
      isUSOnlyAccess
    );
  };

  const getSources = async () => {
    let url = `/api/v1/liveness`;
    const payload = {
      id: datastoreId,
      customerId: getCustomerId(),
    };
    let res = await axiosDenaliPrivate.post(url, payload);
    // setDatastoreSources(ACCESS_SOURCES);

    if (res && !res.err && res.data) {
      setDatastoreSources(res.data);
    } else {
      setDatastoreSources(null);
    }
  };

  const accessTabClicked = () => {
    setSelectedTab("access");
    getSources();
  };

  const getLatitudeAndLongitude = (value, region) => {
    if (value === "latitude") {
      let objRegion = regions.find((r) => r.code === region);
      return objRegion?.latitude || "";
    }
    if (value === "longitude") {
      let objRegion = regions.find((r) => r.code === region);
      return objRegion?.longitude || "";
    }
  };

  useEffect(() => {
    RefreshChart(0, attackPaths[0]?.pathName);
  }, [attackPaths]);

  useEffect(() => {
    if (datastoreSources) {
      let datasourceId = 0;
      let datasources = [];

      datastoreSources?.ten_minutes?.sources?.map((sources) => {
        sources?.sourceIdentity?.sourceIPAddressLocations?.map((source) => {
          datasourceId = datasourceId + 1;
          let latitude = source?.location?.coordinates[0];
          let longitude = source?.location?.coordinates[1];

          datasources.push({
            id: datasourceId,
            userName: sources.sourceIdentity?.userName,
            sourceType: sources.sourceIdentity?.sourceType,
            title: source.region,
            sourceIpAddress: source.sourceIPAddress,
            sourceAsset: source.sourceAsset,
            latitude: latitude
              ? latitude
              : getLatitudeAndLongitude("latitude", source.region),
            longitude: longitude
              ? longitude
              : getLatitudeAndLongitude("longitude", source.region),
            color: "#000",
            name: source.city,
            radius: 7,
            scale: 0.7,
            isComplianceViolation: checkComplianceViolationOnLiveness(source),
          });
        });
      });

      if (selectedDataFlow) {
        const selectedAccessIndex = datasources.findIndex(
          (x) => x.sourceAsset === selectedDataFlow.source.assetId
        );
        if (selectedAccessIndex > -1) {
          handleRefresh(
            selectedAccessIndex,
            datasources[selectedAccessIndex]?.userName || ""
          );
        }
      }

      setUpdatedDatastoreSources(datasources);
    }
  }, [datastoreSources, isUSOnlyAccess]);

  useEffect(() => {
    if (selectedTab === "access") {
      handleRefresh(
        0,
        datastoreSources?.ten_minutes?.sources[0]?.sourceIdentity?.userName ||
          ""
      );
    } else if (selectedTab === "attack-path") {
      handleRefresh(0, attackPaths[0]?.pathName);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (selectedDataFlow) {
      accessTabClicked();
    } else {
      getSources();
    }
  }, []);

  const tabs = [
    {
      title: `ATTACK PATHS - ${attackPaths.length}`,
      active: true,
    },
    {
      title: updatedDatastoreSources?.length
        ? `ACCESS - ${datastoreSources?.ten_minutes?.sources.length || 0}`
        : `ACCESS - 0`,
      active: false,
    },
  ];

  return (
    <div id="bottom-panel-main-container">
      <div className="row">
        <div className="col">
          <div className=" d-flex justify-content-between">
            <div>
              {" "}
              <Tabs
                data={tabs}
                setSelectedTab={setSelectedTab}
                accessTabClicked={accessTabClicked}
                selectedTab={selectedTab}
              />
            </div>
            {/* <div className="d-flex justify-content-center align-items-center">
              {" "}
              <div
                className="col-lg-auto"
                onClick={() => {
                  selectedBar?.numSecurityIssues > 0 &&
                    setIsDatastoreIssuesModal(true);
                }}
                // onClick={() => viewIssues()}
              >
                <span
                  className={
                    selectedBar?.numSecurityIssues > 0
                      ? "card-tabs cursor-pointer"
                      : "card-tabs-disabled"
                  }
                >
                  <b className="p-2"> SECURITY ISSUES </b>
                </span>
              </div>
            </div> */}
          </div>
          {/* <p>Attack Paths listed by Severity</p> */}

          <div class="tab-content">
            <div id="home" class="container tab-pane active">
              {selectedTab !== "access" && (
                <div className="attack-path-table">
                  <div className="table-responsive">
                    <table className="table table-border-partial table-hover">
                      <tbody>
                        {attackPaths.map(function (item, idx) {
                          return (
                            <tr
                              key={idx}
                              className={
                                selected === idx
                                  ? "vuls-row active-path-row"
                                  : "vuls-row"
                              }
                              onClick={() => handleRefresh(idx, item.pathName)}
                            >
                              <td
                                className={`${getScoreBorder(item.pathScore)}`}
                              >
                                {/* <span className="">
                                <div
                                  className={`numberCircle ${getScoreColor(
                                    item.pathScore
                                  )}`}
                                >
                                  {" "}
                                  {parseInt(item.pathScore)}
                                </div>
                              </span> */}
                                {item?.pathAttackStatus && (
                                  <img src={AttackIcon} width="25px" />
                                )}
                              </td>
                              <td
                                className="data-store-name"
                                title={item.pathName}
                              >
                                {formatLine(item.pathName, 50)}
                              </td>
                              <td className="">
                                <div className="d-flex bd-highlight">
                                  {/* <div class="flex-fill bd-highlight d-flex align-items-center justify-content-center">
                              {item.info.title ? (
                                <i class="icon fa fa-circle text-danger incognito-icon"></i>
                              ) : (
                                ""
                              )}{" "}
                            </div> */}
                                  <div className=" d-flex align-items-center">
                                    <div className="d-flex flex-column bd-highlight ">
                                      {/* <div class="bd-highlight">
                                  {" "}
                                  {item.info.title ? (
                                    <div className="col p-0 pl-2 left-panel-title d-flex align-items-start justify-content-start ">
                                      {item.info.title}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div> */}
                                      <div
                                        className="bd-highlight"
                                        title={item.pathDescription}
                                      >
                                        <span className="col p-0 pl-2 col-lg-12 col-md-12 left-panel-subtitle d-flex align-items-start justify-content-start">
                                          {item.pathDescription.length > 50
                                            ? item.pathDescription.slice(
                                                0,
                                                50
                                              ) + "..."
                                            : item.pathDescription}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>{" "}
                                {/* <div
                            className="col col-lg-auto d-flex align-items-center justify-content-center p-1"
                            align="center"
                          >
                          
                          </div> */}
                              </td>

                              {/* <td>
                          <img src="/static/img/ticket-unavailable.svg" /> */}
                              {/* {item.ticket || createTicket ? (
                          <img src="static/img/ticket-available.svg" />
                        ) : (
                          <img src="static/img/ticket-unavailable.svg" />
                        )}
                        {selected === idx ? (
                          <span className="pl-2 data-store-name">
                            {createTicket ? item.ticket + 1 : item.ticket}{" "}
                          </span>
                        ) : (
                          <span className="pl-2 data-store-name">
                            {item.ticket}
                          </span>
                        )} */}
                              {/* </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
            <div id="menu1" class="container tab-pane active">
              {selectedTab === "access" && (
                <div className="attack-path-table">
                  <div className="table-responsive">
                    <table className="table table-border-partial table-hover">
                      <tbody>
                        {updatedDatastoreSources.length > 0 &&
                          updatedDatastoreSources.map(function (item, idx) {
                            return (
                              <tr
                                key={idx}
                                className={
                                  selected === idx
                                    ? "vuls-row active-path-row"
                                    : "vuls-row cursor-default"
                                }
                                onClick={() =>
                                  handleRefresh(idx, item?.userName || "")
                                }
                              >
                                <td>
                                  {item.isComplianceViolation && (
                                    <img
                                      src={complianceViolation}
                                      title="Compliance Violation"
                                    />
                                  )}
                                </td>
                                <td
                                  className="data-store-name"
                                  title={item?.userName || ""}
                                >
                                  {formatLine(item?.userName || "", 50)}
                                </td>
                                <td className="">
                                  <div className="d-flex bd-highlight jusity">
                                    <div className=" d-flex align-items-center">
                                      <div className="d-flex flex-column bd-highlight ">
                                        <div
                                          className="bd-highlight"
                                          title={item?.sourceType || ""}
                                        >
                                          <span className="col p-0 pl-2 col-lg-12 col-md-12 left-panel-subtitle d-flex align-items-start justify-content-start">
                                            {item?.sourceType?.length > 50
                                              ? item?.sourceType?.slice(0, 50) +
                                                "..."
                                              : item?.sourceType || ""}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="">
                                  <div className="d-flex bd-highlight">
                                    <span className="left-panel-subtitle">
                                      {item.sourceIpAddress}
                                    </span>
                                  </div>
                                </td>
                                <td className="">
                                  <div className="d-flex bd-highlight">
                                    <span className="left-panel-subtitle">
                                      {datastoreSources?.ten_minutes
                                        ?.time_window_end
                                        ? moment(
                                            moment
                                              .utc(
                                                datastoreSources?.ten_minutes
                                                  ?.time_window_end
                                              )
                                              .toDate()
                                          )
                                            .local()
                                            .fromNow()
                                        : "N/A"}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BottomPanelComponent);
