import React, { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch } from "react-redux";
import { listSensitiveData } from "../../../redux/slices/dataStoreSlice.js";

import { datastoreIcons } from "../../../utils/styles";
import { getCustomerId } from "../../../utils/SessionHelper";

const SensitiveData = ({ selectedAccount, setSensitiveFilter }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const getDatastoreStats = async () => {
    const resp = await dispatch(
      listSensitiveData({
        customerId: getCustomerId(),
        cloudAccountId:
          selectedAccount.value === "all" ? null : selectedAccount.value,
      })
    );
    if (resp && resp.payload) {
      setData(resp.payload);
    }
  };

  useEffect(() => {
    getDatastoreStats();
  }, [selectedAccount]);

  const tags = {
    "PII-SSN": "Social Security Numbers",
    "PII-CC": "Credit Cards",
    "PII-MN": "Mobile Numbers",
    "PII-ADDRESS": "Street Addresses",
    "PII-IP": "IP Addresses",
    "PII-EMAILS": "Emails",
    "PII-DL": "Drivers Licenses",
    "PII-NAMES": "Names",
  };

  return (
    <div className="sensitive-data-container">
      <h6>Sensitive Data</h6>
      <div className="sensitive-table">
        <div className="table-responsive">
          <table className={`mb-0 pb-3 table table-border-less table-hover`}>
            <thead>
              <tr className="vuls-row">
                <th scope="col">Tag</th>
                <th scope="col">Count</th>
                <th scope="col">Datastores</th>
                <th scope="col"></th>
              </tr>
            </thead>

            {data && data.length ? (
              <tbody>
                {data.map(function (item, idx) {
                  return (
                    <tr
                      key={idx}
                      className="datastores-row"
                      onClick={() => setSensitiveFilter(item.tag)}
                    >
                      <td className="tag">
                        <div className="wrap-with-ellipsis">
                          {tags[item.tag]}
                        </div>
                      </td>
                      <td className="count">{item.count}</td>
                      <td className="datastores">{item.datastores}</td>
                      <td className="data-store-name">
                        <img src={datastoreIcons["generic"]} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              ""
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default SensitiveData;
