import { getScoreColor } from "../../../utils/styles";
import { useHistory } from "react-router-dom";
import "../Home.scss";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import BarPopup from "../bar-popup/BarPopup";
import HackerIcon from "../../../assets/img/icons/hacker-icon.svg";

const Bar = ({
  logo,
  type,
  numAttackPath,
  numSecurityIssues,
  score,
  datastore,
  attack,
  vunerability,
  handleBar,
  bar,
  selectedBar,
  risktype,
  rank,
  impacts,
  tab,
  page,
  firstAccountStatus,
}) => {
  const history = useHistory();

  const getDatstoreClassName = () => {
    let res = attack
      ? `colum ${
          risktype === "high"
            ? "colum-high"
            : risktype === "low"
            ? "colum-low"
            : "colum-medium"
        } ${
          selectedBar && selectedBar.datastoreId === bar.datastoreId
            ? "column-active"
            : ""
        }`
      : `colum ${
          selectedBar && selectedBar.datastoreId === bar.datastoreId
            ? "column-active"
            : ""
        }`;

    return res;
  };

  const getIssuesClassName = () => {
    let res = attack
      ? `colum ${
          risktype === "high"
            ? "colum-high"
            : risktype === "low"
            ? "colum-low"
            : "colum-medium"
        } ${
          selectedBar &&
          selectedBar["securityIssueInstanceId"] ===
            bar["securityIssueInstanceId"]
            ? "column-active"
            : ""
        }`
      : `colum ${
          selectedBar &&
          selectedBar["securityIssueInstanceId"] ===
            bar["securityIssueInstanceId"]
            ? "column-active"
            : ""
        }`;

    return res;
  };

  const getRank = (rank) => {
    return page === 1
      ? rank
      : rank === 10
      ? `${page * rank}`
      : `${page - 1}${rank}`;
  };
  const viewDetails = (item) => {
    if (firstAccountStatus?.first_acct_risk_complete) {
      sessionStorage.setItem("selected_bar", JSON.stringify(item));
      if (tab === 1) {
        history.push(
          `/datastore-details/${item.datastoreId?.replaceAll(
            "/",
            "_"
          )}/${item.assetId?.replaceAll("/", "_")}`
        );
      } else {
        history.push(
          `/vulnerabilities-details/${selectedBar?.vulnerabilityID.replaceAll(
            "/",
            "_"
          )}/${item?.instanceLocation.replaceAll("/", "_")}/${
            item?.securityIssueInstanceId
          }`
        );
      }
    }
  };

  return (
    <Popup
      trigger={(open) => (
        <div
          className={`${
            tab === 1 ? getDatstoreClassName() : getIssuesClassName()
          } ${
            firstAccountStatus?.first_acct_risk_complete
              ? "cursor-pointer"
              : "cursor-default"
          }`}
          onClick={() => viewDetails(bar)}
          // onDoubleClick={() => viewDetails(bar)}
        >
          <div
            className={`bar ${getScoreColor(
              tab === 1 ? bar.cdDatastoreRiskScore : bar.cdIssueRiskScore
            )}`}
          ></div>
          <div
            className={`head ${tab === 1 ? "datastore-head" : "issues-head"}`}
            title={type}
          >
            {!vunerability ? (
              <div className="">
                {bar.attackStatus && (
                  <img
                    src={HackerIcon}
                    width="21px"
                    className="hacker-icon"
                    title="Direct Anomaly"
                  />
                )}
                <img
                  src={logo}
                  width="21px"
                  className={vunerability ? "bug-img" : "icon-img"}
                />
              </div>
            ) : (
              ""
            )}
            <span className={vunerability ? "bug-span" : "icon-span"}>
              {type ? type.slice(0, tab === 1 ? 3 : 14) : ""}
            </span>
          </div>
          <div className="body upper-body b2">
            <div className="range r2">
              <div
                className="upper"
                style={{
                  height:
                    (numSecurityIssues <= 100
                      ? numSecurityIssues > 0
                        ? numSecurityIssues
                        : 0
                      : 100) + "px",
                }}
              >
                <div
                  className={
                    tab === 1
                      ? numSecurityIssues > 85
                        ? "number-bottom"
                        : "number-top"
                      : numSecurityIssues > 85
                      ? "number-bottom issues-number-bottom"
                      : "number-top"
                  }
                >
                  {numSecurityIssues > 100 ? "100+" : numSecurityIssues}
                </div>
              </div>
            </div>
          </div>

          <div className="body lower-body b2">
            <div className="range r2">
              <div
                className="lower"
                style={{
                  height: numAttackPath
                    ? vunerability
                      ? (numAttackPath <= 100
                          ? numAttackPath > 0
                            ? numAttackPath + 48
                            : 0
                          : 100) + "px"
                      : (numAttackPath <= 100
                          ? numAttackPath > 0
                            ? numAttackPath
                            : 0
                          : 100) + "px"
                    : "0px",
                }}
              ></div>
              <div
                className={`${
                  numAttackPath > 85 ? "number-top" : "number-bottom"
                } ${tab === 2 ? "issues-number-bottom" : ""}`}
              >
                {numAttackPath
                  ? numAttackPath > 100
                    ? "100+"
                    : numAttackPath
                  : 0}{" "}
                {/* {tab === 1 && (
              <img src={AttackIcon} width="17px" title="Indirect Anomaly" />
            )} */}
              </div>
            </div>
          </div>
        </div>
      )}
      position="right center"
      closeOnDocumentClick
      className="bar-popup"
      arrow={false}
      on={["hover", "focus"]}
    >
      <BarPopup
        risktype={risktype}
        datastore={datastore}
        tab={tab}
        bar={bar}
        selectedBar={selectedBar}
      />
    </Popup>
  );
};

export default Bar;
