import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { addTicket, fetchTickets } from "../apis/ticketAPI";

const initialState = {
  tickets: [],
};

// Async calls.

export const listTicketsAsync = createAsyncThunk("tickets/list", async () => {
  const response = await fetchTickets();
  return response.data.issues;
});

export const addTicketAsync = createAsyncThunk(
  "tickets/addTicket",
  async (params) => {
    const response = await addTicket(params);
    return response.data;
  }
);

// Account slice for configuring reducers and actions.
export const ticketSlice = createSlice({
  name: "tickets",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(listTicketsAsync.fulfilled, (state, action) => {
        state.tickets = action.payload;
      })
      .addCase(listTicketsAsync.rejected, (state, action) => {
        state.tickets = [];
      });
  },
});

export default ticketSlice.reducer;

// Selectors will go here
export const integrationById = (state, integrationId) =>
  state.integrations.find((integration) => integration.id === integrationId);
