import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchPolicies,
  addPolicy,
  deletePolicy,
  updatePolicy,
  getPolicies,
} from "../apis/policyAPI";

const initialState = {
  policies: [],
};

// Async calls.
export const listPoliciesAsync = createAsyncThunk("policy/list", async () => {
  const response = await fetchPolicies();
  console.log(response);

  return response.data;
});

export const getAllPolicies = createAsyncThunk("policy/all", async (params) => {
  const response = await getPolicies(params);

  return response.data;
});

export const addPolicyAsync = createAsyncThunk(
  "policy/addPolicy",
  async (params) => {
    try {
      const response = await addPolicy(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const updatePolicyAsync = createAsyncThunk(
  "integration/updatePolicy",
  async (params) => {
    const response = await updatePolicy(params._id, params);
    return response.data;
  }
);

export const deletePolicyAsync = createAsyncThunk(
  "policy/deletePolicy",
  async (params) => {
    try {
      const response = await deletePolicy(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

// Account slice for configuring reducers and actions.
export const policySlice = createSlice({
  name: "policy",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(listPoliciesAsync.fulfilled, (state, action) => {
        state.policies = action.payload;
      })
      .addCase(listPoliciesAsync.rejected, (state, action) => {
        state.policies = [];
      });
  },
});

export default policySlice.reducer;

// Selectors will go here
export const policyById = (state, policyId) =>
  state.integrations.find((policy) => policy.id === policyId);
