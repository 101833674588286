import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { listAccountsAsync } from "./redux/slices/accountSlice";
import { firstAccountStatStatus } from "./redux/slices/dashboardSlice";
import { getCustomerId, getUserId } from "./utils/SessionHelper";
import Account from "./views/Account";

import CheckList from "./views/CheckList";
import DataStoreDetails from "./views/Datastore/datastore-details.view";
import DataStore from "./views/Datastore/datastore.view";
import Home from "./components/home/Home";
import CompleteProfile from "./views/Login/complete-profile.view";
import ForgotPassword from "./views/Login/forgot-password.view";
import LoginCallback from "./views/Login/login-callback.view";
import Login from "./views/Login/login.view";

import ResetPassword from "./views/Login/reset-password.view";
import Timeout from "./views/Login/timeout.view";
import VerifyEmail from "./views/Login/verify-email.view";
import Register from "./views/Register/Register.view";
import Reports from "./views/Reports";
import Settings from "./views/Settings";
import Test from "./views/test";
import Tickets from "./views/Tickets";
import UserManagement from "./views/UserManagement";
import VulnerabilitiesDetails from "./views/Vulnerabilities/vulnerabilities-details.view";
import Vulnerabilities from "./views/Vulnerabilities/vulnerabilities.view";
import Identity from "./views/Identity";
import Assets from "./views/Assets";
// import Identity from "./views/Identity";
import PolicySetting from "./views/PolicySetting/PolicySetting.view";
import Header from "./components/layout/Header";
import Sidebar from "./components/layout/Sidebar";
import "./components/layout/Layout.scss";
import { getSelectedDatastoreAccount } from "./utils/SessionHelper";
import ReportDoc from "./components/reports/report";
import { useSelector } from "react-redux";
import EmbededUiModal from "./components/modal/embeded-ui/EmbededUiModal";
import Footer from "./components/footer";
import Doc from "./views/Docs/doc.view";

const App = () => {
  const [loggendIn, setLoggendIn] = useState(false);
  const [path, setPath] = useState("/");
  const [settings, setSetting] = useState(false);
  const [ticket, setTicket] = useState(false);
  const [ticket2, setTicket2] = useState(false);
  const [tab, setTab] = useState(1);
  const [infoModal, setInfoModal] = useState(false);
  const isIssueViewVisible = useSelector(
    (state) => state.dashboard.isIssueViewVisible
  );
  const expandedSidebar = useSelector((state) => state.dashboard.sidebar);

  const [accountAvailable, setAccountAvailable] = useState(false);

  const defaultAccount = getSelectedDatastoreAccount() || {
    value: "all",
    label: "All",
  };
  const [selectedAccount, setSelectedAccount] = useState(defaultAccount);

  const dispatch = useDispatch();

  const checkAccounts = async () => {
    dispatch(
      firstAccountStatStatus({
        customerId: getCustomerId(),
      })
    );
    const resp = await dispatch(listAccountsAsync());
    if (resp && resp.payload) {
      if (resp?.payload?.length === 0) {
        setAccountAvailable(false);
      } else {
        setAccountAvailable(true);
      }
    }
  };
  useEffect(() => {
    let token = localStorage.getItem("authToken");
    if (token) {
      checkAccounts();
      setLoggendIn(true);
    }
  }, []);

  return (
    <div>
      <Router>
        <Switch>
          <div className="d-flex">
            {loggendIn ? <Sidebar expandedSidebar={expandedSidebar} /> : ""}

            <div className="d-flex flex-column vw-100">
              {loggendIn ? (
                <Header
                  expandedSidebar={expandedSidebar}
                  selectedAccount={selectedAccount}
                  setSelectedAccount={setSelectedAccount}
                  setInfoModal={setInfoModal}
                  isIssueViewVisible={isIssueViewVisible}
                />
              ) : (
                ""
              )}
              {loggendIn ? (
                <div
                  className={
                    expandedSidebar ? "cd-container-extended" : "cd-container"
                  }
                >
                  <div className="wrapper">
                    {/* <Header
                path={path}
                setSetting={setSetting}
                setProfile={setProfile}
                profile={profile}
                settings={settings}
                ticket={ticket}
                setTicket={setTicket}
                ticket2={ticket2}
                setTicket2={setTicket2}
              />
              <Sidebar
                setTab={setTab}
                tab={tab}
                path={path}
                setPath={setPath}
              /> */}
                    {/* <Header /> */}
                    {/* <Sidebar /> */}
                    <div id="content-page" className="content-page">
                      <div className="container-fluid">
                        <Route
                          exact
                          path="/test"
                          render={(props) => <Test />}
                        />
                        <Route
                          exact
                          path="/on-boarding/:id"
                          render={(props) => (
                            <CompleteProfile setLoggendIn={setLoggendIn} />
                          )}
                        />
                        <Route
                          exact
                          path="/settings/admin"
                          render={(props) => (
                            <UserManagement setPath={setPath} />
                          )}
                        />
                        <Route
                          exact
                          path="/report"
                          render={(props) => <ReportDoc setPath={setPath} />}
                        />
                        <Route
                          exact
                          path="/reports"
                          render={(props) => <Reports setPath={setPath} />}
                        />
                        <Route
                          exact
                          path="/checklist"
                          render={(props) => (
                            <CheckList setSetting={setSetting} tab={tab} />
                          )}
                        />
                        <Route
                          exact
                          path="/tickets"
                          render={(props) => (
                            <Tickets setTicket2={setTicket2} tab={tab} />
                          )}
                        />
                        <Route
                          exact
                          path="/settings/integrations"
                          render={(props) => (
                            <Settings
                              setSetting={setSetting}
                              setPath={setPath}
                              tab={tab}
                            />
                          )}
                        />{" "}
                        <Route
                          exact
                          path="/settings/policy-settings"
                          render={(props) => (
                            <PolicySetting setPath={setPath} />
                          )}
                        />
                        {/* <Route
                    exact
                    path="/cloud/identity"
                    render={(props) => <Identity setPath={setPath} tab={tab} />}
                  /> */}
                        <Route
                          exact
                          path="/cloud/assets"
                          render={(props) => (
                            <Assets
                              setPath={setPath}
                              tab={tab}
                              path={path}
                              selectedAccount={selectedAccount}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/cloud/identity"
                          render={(props) => (
                            <Identity
                              setPath={setPath}
                              tab={tab}
                              selectedAccount={selectedAccount}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/account"
                          render={(props) => (
                            <Account setPath={setPath} tab={tab} />
                          )}
                        />
                        <Route
                          exact
                          path="/datastore"
                          render={(props) => (
                            <DataStore
                              setPath={setPath}
                              tab={tab}
                              selectedAccount={selectedAccount}
                            />
                          )}
                        />{" "}
                        <Route
                          exact
                          path="/datastore-details/:id/:assetId"
                          render={(props) => (
                            <DataStoreDetails
                              setPath={setPath}
                              setTicket={setTicket}
                              tab={tab}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/vulnerabilities"
                          render={(props) => (
                            <Vulnerabilities
                              setPath={setPath}
                              selectedAccount={selectedAccount}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/docs/wiz-integration"
                          render={(props) => (
                            <Doc
                              setPath={setPath}
                              selectedAccount={selectedAccount}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/vulnerabilities/:assetId"
                          render={(props) => (
                            <Vulnerabilities setPath={setPath} />
                          )}
                        />
                        <Route
                          exact
                          path="/vulnerabilities-details/:id/:assetId/:securityIssueInstanceId"
                          render={(props) => (
                            <VulnerabilitiesDetails setPath={setPath} />
                          )}
                        />
                        {/* <Route exact path="/admin" render={(props) => <Admin />} /> */}
                        <Route
                          exact
                          path="/docs/"
                          render={(props) => (
                            <Home
                              tab={tab}
                              setPath={setPath}
                              selectedAccount={selectedAccount}
                              infoModal={infoModal}
                              isIssueViewVisible={isIssueViewVisible}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/"
                          render={(props) => (
                            <Home
                              tab={tab}
                              setPath={setPath}
                              selectedAccount={selectedAccount}
                              infoModal={infoModal}
                              isIssueViewVisible={isIssueViewVisible}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <Route
                    exact
                    path="/docs/wiz-integration"
                    render={(props) => <Login setLoggendIn={setLoggendIn} />}
                  />

                  <Route
                    exact
                    path="/docs/"
                    render={(props) => <Login setLoggendIn={setLoggendIn} />}
                  />

                  <Route exact path="/test" render={(props) => <Test />} />
                  <Route
                    exact
                    path="/on-boarding/:id"
                    render={(props) => (
                      <CompleteProfile setLoggendIn={setLoggendIn} />
                    )}
                  />
                  <Route
                    exact
                    path="/reset-password"
                    render={(props) => <ResetPassword />}
                  />
                  <Route
                    exact
                    path="/forgot-password"
                    render={(props) => <ForgotPassword />}
                  />

                  <Route
                    exact
                    path="/timeout"
                    render={(props) => <Timeout />}
                  />

                  <Route
                    exact
                    path="/verify-email"
                    render={(props) => <VerifyEmail />}
                  />
                  <Route
                    exact
                    path="/register"
                    render={(props) => <Register />}
                  />
                  <Route
                    exact
                    path="/callback"
                    render={(props) => (
                      <LoginCallback setLoggendIn={setLoggendIn} />
                    )}
                  />
                  <Route
                    exact
                    path="/"
                    render={(props) => <Login setLoggendIn={setLoggendIn} />}
                  />
                </>
              )}

              <Footer />
            </div>
          </div>
        </Switch>
      </Router>

      {infoModal && <EmbededUiModal handleModal={setInfoModal} />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;
