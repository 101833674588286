import React, { memo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../datastore/datastore.scss";
import moment from "moment";
import { getScoreColor } from "../../utils/styles";
import AttackIcon from "../../assets/img/icons/attack-icon.svg";
import HackerIcon from "../../assets/img/icons/hacker-icon.svg";
import LivenessIcon from "../../assets/img/icons/liveness-icon.svg";
import LockIcon from "../../assets/img/icons/lock-icon.svg";
import { useEdges } from "react-flow-renderer";
import { dateFormatter } from "../../utils/dataHandler";
const Table = ({
  currentPosts,
  severity,
  page = "datastore-details",
  sortData,
  tablePadding = "px-3",
}) => {
  const history = useHistory();

  const viewDetails = (item) => {
    sessionStorage.setItem("selected_bar", JSON.stringify(item));
    history.push(
      `/datastore-details/${item.datastoreId?.replaceAll(
        "/",
        "_"
      )}/${item.assetId?.replaceAll("/", "_")}`
    );
  };

  return (
    <div className="row">
      <div className="col col-xl-12">
        <div className="table-responsive">
          <table
            className={`mb-0 pb-3 table table-border-less table-hover ${tablePadding}`}
          >
            <thead>
              <tr className="vuls-row">
                <th
                  scope="col"
                  onClick={() => sortData("cdDatastoreRiskScore")}
                >
                  Data Risk
                  <i className="las la-sort"></i>
                </th>
                <th scope="col" onClick={() => sortData("name.keyword")}>
                  Name <i className="las la-sort"></i>
                </th>
                <th
                  scope="col"
                  onClick={() => sortData("metadata.cloudAccountId.keyword")}
                >
                  Account Id <i className="las la-sort"></i>
                </th>
                <th scope="col" onClick={() => sortData("type.keyword")}>
                  Type <i className="las la-sort"></i>
                </th>
                <th
                  scope="col"
                  onClick={() => sortData("metadata.createdAt.keyword")}
                >
                  Discovered On <i className="las la-sort"></i>
                </th>
                <th scope="col" onClick={() => sortData("size.keyword")}>
                  Size <i className="las la-sort"></i>
                </th>
                <th
                  scope="col"
                  onClick={() => sortData("mountLocation.keyword")}
                >
                  Location <i className="las la-sort"></i>
                </th>
                {page === "datastore-details" && <th scope="col"></th>}
              </tr>
            </thead>

            <tbody>
              {currentPosts.map(function (item, idx) {
                return (
                  <tr
                    key={idx}
                    onClick={() => viewDetails(item)}
                    className="vuls-row"
                  >
                    <td>
                      <div
                        className={`${getScoreColor(
                          item.cdDatastoreRiskScore
                        )}`}
                      ></div>
                    </td>
                    <td className="data-store-name">
                      <div className="wrap-with-ellipsis" title={item?.name}>
                        {item?.name}
                      </div>
                    </td>
                    <td className="table-data">
                      {" "}
                      <div
                        className="wrap-with-ellipsis"
                        title={item?.["metadata.cloudAccountId"]}
                      >
                        {item?.["metadata.cloudAccountId"]}
                      </div>
                    </td>
                    <td className="table-type">{item.type}</td>
                    <td className="table-data">
                      {item["metadata.createdAt"]
                        ? dateFormatter(item["metadata.createdAt"])
                        : "-"}
                    </td>
                    <td className="table-data">{item.size}</td>
                    <td>
                      <a href="#" className="data-store-name">
                        {item.mountLocation}
                      </a>
                    </td>
                    {page === "datastore-details" && (
                      <td>
                        {" "}
                        {item?.indirectAttackStatus && (
                          <img
                            src={AttackIcon}
                            width="25px"
                            title="Indirect Anomaly"
                          />
                        )}{" "}
                        {item?.attackstatus && (
                          <img
                            src={HackerIcon}
                            width="21px"
                            title="Direct Anomaly"
                            className="mr-1"
                          />
                        )}{" "}
                        {item?.livenessInfo && (
                          <img
                            src={LivenessIcon}
                            width="20px"
                            className="mr-1"
                            title="Live Traffic"
                          />
                        )}{" "}
                        {item?.sensitiveScore >= 80 && (
                          <img
                            src={LockIcon}
                            width="20px"
                            title="Highly Sensitive"
                          />
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default memo(Table);
