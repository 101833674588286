import "../Home.scss";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { LOADER_CONFIG, EVEREST_URL } from "../../../utils/Constants";
import { useEffect, useState } from "react";
import { getCustomerId } from "../../../utils/SessionHelper";
import { getIssuesApiV2 } from "../../../apis";
import { getScoreColor } from "../../../utils/styles";
import { useHistory } from "react-router-dom";
import { isJsonEmpty } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { listTopIssueTypesAsync } from "../../../redux/slices/issuesSlice";
import { useDispatch } from "react-redux";

const SecurityIssues = ({
  setHoveredDatastoreIds,
  setBreachCost,
  selectedAccount,
  firstAccountStatus,
}) => {
  const [securityIssueList, setSecurityIssueList] = useState([]);
  const [topIssueTypes, setTopIssueTypes] = useState([]);
  const [datastoreClicked, setDatastoreClicked] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const expandedSidebar = useSelector((state) => state.dashboard.sidebar);
  const [selectedFilter, setSelectedFilter] = useState("top-issues");
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedFilter === "top-issues") {
      getIssues();
    } else if (selectedFilter === "top-issue-types") {
      getIssueTypes();
    }
  }, [selectedAccount, selectedFilter]);

  const getIssues = async () => {
    setLoading(true);
    const payload = {
      customerId: getCustomerId(),
      limit: 50,
      page: 1,
      sortType: "desc",
      ordering: "cdIssueRiskScore",
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
      risk_accepted: false,
    };
    let res = await getIssuesApiV2(payload);
    if (res && !res.err && res.data) {
      setSecurityIssueList(res.data);
      setLoading(false);
    } else {
      setLoading(false);
      setSecurityIssueList([]);
    }
  };

  const getIssueTypes = async () => {
    setLoading(true);
    const resp = await dispatch(
      listTopIssueTypesAsync({
        customerId: getCustomerId(),
        cloudAccountId:
          selectedAccount.value === "all" ? null : selectedAccount.value,
      })
    );
    if (resp && resp.payload && !resp.payload.err) {
      setTopIssueTypes(resp?.payload);
      setLoading(false);
    } else {
      setTopIssueTypes([]);
      setLoading(false);
    }
  };

  const viewIssue = (issue) => {
    if (firstAccountStatus?.first_acct_risk_complete) {
      history.push(
        `/vulnerabilities-details/${issue?.vulnerabilityID.replaceAll(
          "/",
          "_"
        )}/${issue?.instanceLocation.replaceAll("/", "_")}/${
          issue?.securityIssueInstanceId
        }`
      );
    }
  };

  const showImpactedDatastores = (issue) => {
    let datastores = [];
    if (issue.impactedDatastores.length > 0) {
      setBreachCost(issue.issueDatastoreSaving);
      issue.impactedDatastores.map((datastore) => {
        datastores.push(datastore.datastoreId);
      });
    } else {
      setBreachCost(0);
    }
    setHoveredDatastoreIds(datastores);
  };

  const viewAllIssues = () => {
    if (firstAccountStatus?.first_acct_risk_complete) {
      history.push("/vulnerabilities");
    }
  };

  const getInstanceName = (value) => {
    if (value && value.length > 20) {
      let strVal = value
        .substring(value.lastIndexOf(":") + 1)
        .replace("instance/", "");

      if (strVal) {
        const charLimit = expandedSidebar ? 20 : 40;
        return `${strVal.slice(
          0,
          strVal.length < charLimit ? strVal.length : charLimit
        )}${strVal.length < charLimit ? "" : "..."}`;
      } else {
        return value;
      }
    } else {
      return value;
    }
  };

  return (
    <BlockUi
      tag="div"
      id="securityissues-layout"
      blocking={loading}
      loader={
        <Loader active type={LOADER_CONFIG.type} color={LOADER_CONFIG.color} />
      }
    >
      <ul class="nav nav-pills mb-1" id="pills-tab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="top-issues-tab"
            data-toggle="pill"
            href="#top-issues"
            role="tab"
            aria-selected="false"
            onClick={() => setSelectedFilter("top-issues")}
          >
            Top Issues (50)
          </a>
        </li>
        {!EVEREST_URL.includes("stage") && (
          <li class="nav-item">
            <a
              class="nav-link"
              id="top-issue-types"
              data-toggle="pill"
              href="#top-issue-types-tab"
              role="tab"
              aria-controls="top-issue-types"
              aria-selected="false"
              onClick={() => setSelectedFilter("top-issue-types")}
            >
              Top Issue Types
            </a>
          </li>
        )}

        <div
          className={`d-flex ml-auto align-items-center view-all mr-3 ${
            firstAccountStatus?.first_acct_risk_complete
              ? "cursor-pointer"
              : "cursor-default"
          }`}
          onClick={() => viewAllIssues()}
        >
          See all >{" "}
        </div>
      </ul>

      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="top-issues"
          role="tabpanel"
          aria-labelledby="high-risk-tab"
        >
          <div className="mt-4 top-issues table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Instance</th>
                  <th>Type</th>
                  <th>Impacted Datastores</th>
                </tr>
              </thead>
              <tbody>
                {!isJsonEmpty(securityIssueList) &&
                  securityIssueList &&
                  securityIssueList.map((issue, id) => {
                    return (
                      <tr
                        onClick={() => viewIssue(issue)}
                        key={`securityIssue${id}`}
                        className={`${
                          firstAccountStatus?.first_acct_risk_complete
                            ? "cursor-pointer"
                            : "cursor-default"
                        }`}
                      >
                        <td title={issue?.name}>
                          <span
                            className={`red-dot ${getScoreColor(
                              issue?.cdIssueRiskScore
                            )}`}
                          >
                            {" "}
                          </span>{" "}
                          {issue?.name.length > 11
                            ? issue?.name.slice(0, 11) + "..."
                            : issue?.name || ""}
                        </td>
                        <td title={issue?.instanceLocation}>
                          {getInstanceName(issue?.instanceLocation)}
                        </td>
                        <td title={issue?.issueType}>
                          {" "}
                          {issue?.issueType.length > 17
                            ? issue?.issueType.slice(0, 17) + "..."
                            : issue?.issueType || ""}
                        </td>{" "}
                        <td
                          className={
                            datastoreClicked === id ? "datastore-clicked" : ""
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            showImpactedDatastores(issue);
                            setDatastoreClicked(id);
                          }}
                        >
                          {issue?.impactedDatastoresCount}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="tab-pane fade show "
          id="top-issue-types-tab"
          role="tabpanel"
          aria-labelledby="top-issue-types"
        >
          <div className="mt-4 top-issue-types table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Severity</th>
                  <th>Type</th>
                  <th>Total Instances</th>
                </tr>
              </thead>
              <tbody>
                {!isJsonEmpty(topIssueTypes) &&
                  topIssueTypes &&
                  topIssueTypes.map((issue, id) => {
                    return (
                      <tr
                        // onClick={() => viewIssue(issue)}
                        key={`securityIssue${id}`}
                        className={`${
                          firstAccountStatus?.first_acct_risk_complete
                            ? "cursor-pointer"
                            : "cursor-default"
                        }`}
                      >
                        <td title={issue?.title}>
                          <span
                            className={`red-dot ${getScoreColor(issue?.score)}`}
                          >
                            {" "}
                          </span>{" "}
                          {issue?.title.length > 15
                            ? issue?.title.slice(0, 15) + "..."
                            : issue?.title || ""}
                        </td>
                        <td title={issue?.severity}>
                          {issue?.severity ? issue?.severity : null}
                        </td>
                        <td>
                          {" "}
                          {issue?.issueType.length > 15
                            ? issue?.issueType.slice(0, 15) + "..."
                            : issue?.issueType || ""}
                        </td>{" "}
                        <td
                          className={
                            datastoreClicked === id ? "datastore-clicked" : ""
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            showImpactedDatastores(issue);
                            setDatastoreClicked(id);
                          }}
                        >
                          {issue?.occurrence}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>{" "}
        </div>
      </div>
    </BlockUi>
  );
};

export default SecurityIssues;
