import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { getDataStoresApi, getDataStoresApiV2 } from "../../apis/index.js";
import NoDataContainer from "../../components/cards/no-data/index.jsx";
import Cards from "../../components/datastore/cards";
import Table from "../../components/datastore/table";
import Pagination from "../../components/pagination/Pagination";
import { LOADER_CONFIG, REACT_APP_DATASTORE_API } from "../../utils/Constants";
import { addIndex } from "../../utils/dataHandler.js";
import {
  getCustomerId,
  getSelectedDatastoreAccount,
  setSelectedDatastoreAccount,
} from "../../utils/SessionHelper";
import "./datastoreView.scss";
import SensitiveData from "../../components/datastore/dataInventory/sensitive-data.jsx";
import DataOverview from "../../components/datastore/dataInventory/data-overview.jsx";

const Datastore = ({ setPath, tab, selectedAccount }) => {
  const defaultAccount = getSelectedDatastoreAccount() || {
    value: "all",
    label: "All",
  };
  const limit = 10;
  const page_ = sessionStorage.getItem("page");

  const [page, setPage] = useState(page_ ? parseInt(page_) : 1);
  const [inputText, setInputText] = useState("");
  const [currentPosts, setCurrentPosts] = useState([]);
  const [severity, setSeverity] = useState(null);
  const [sensitiveFilter, setSensitiveFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState("cdDatastoreRiskScore");
  const [sortType, setSortType] = useState("desc");
  const [totalDatastoresCount, setTotalDatastoresCount] = useState(0);
  const [search, setSearch] = useState(null);

  const handleSensitiveFilter = (val) => {
    setSensitiveFilter(val);
    setSeverity("sensitive");
  };

  const handleSeverity = (type) => {
    setSensitiveFilter(null);
    setSeverity(type);
    setPage(1);
  };

  useEffect(() => {
    document.title = "Cloud Defense - Datastores";
    setPath(window.location.pathname);
  }, []);

  const getDatastores = async () => {
    setLoading(true);
    const cloudAccountId =
      selectedAccount.value === "all" ? null : selectedAccount.value;

    let payload = {
      customerId: getCustomerId(),
      limit: limit,
      page: page,
      cloudAccountId: cloudAccountId,
      ordering: sortField,
      severity:
        !severity || severity.includes("TOTAL DATA STORES")
          ? null
          : severity.replaceAll(" ", "_"),
    };
    const search_ =
      search && search.includes(":") ? search.split(":")[1] : search;
    const keyword_ =
      search && search.includes(":") ? search.split(":")[0] : null;

    if (REACT_APP_DATASTORE_API === "OPENSEARCH") {
      let severity_ =
        !severity || severity.includes("TOTAL DATA STORES")
          ? null
          : severity.includes("SENSITIVE")
          ? "sensitive"
          : severity.replaceAll(" ", "_");

      payload = {
        customerId: getCustomerId(),
        q: search_,
        dataset: "datastores",
        exact: false,
        page: page,
        limit: limit,
        ordering: sortField,
        sortType: sortType,
        cloudAccountId: cloudAccountId,
        severity: severity_,
        sensitiveFilter: sensitiveFilter,
      };

      if (keyword_) {
        payload.keyword = keyword_;
      }
    }

    let res =
      REACT_APP_DATASTORE_API === "OPENSEARCH"
        ? await getDataStoresApiV2(payload)
        : await getDataStoresApi(payload);

    if (res.err) {
      setLoading(false);
      return;
    }

    if (!res.data) return;
    setTotalDatastoresCount(res.count);
    res = addIndex(res.data);
    setCurrentPosts(res);
    setLoading(false);
  };

  const sortData = (field) => {
    if (field === sortField && sortType === "asc") {
      setSortType("desc");
    } else if (field === sortField && sortType === "desc") {
      setSortType("asc");
    } else {
      setSortType("desc");
    }

    setSortField(field);
    setPage(1);
  };

  useEffect(() => {
    setPage(1);
    setSelectedDatastoreAccount(selectedAccount);
  }, [selectedAccount]);

  useEffect(() => {
    getDatastores();
  }, [page, selectedAccount, sortField, severity, sortType, sensitiveFilter]);

  useEffect(() => {
    sessionStorage.setItem("page", page);
  }, [page]);

  const makeSearch = (ev) => {
    ev.preventDefault();
    getDatastores();
  };

  return (
    <div id="datastores">
      <div className="row align-items-center">
        <div className="col-12 col-md-12 col-xl-4 mb-2 page-heading">
          {"Data Stores > "}
          <span className="text-info">{"Overview"}</span>
        </div>
      </div>

      <div className="data-inventroy">
        <div className="inventroy-title-container">
          <h5 className="text-white">Data Inventory</h5>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <SensitiveData
              selectedAccount={selectedAccount}
              setSensitiveFilter={handleSensitiveFilter}
            />
          </div>
          <div className="col-sm-6">
            <DataOverview selectedAccount={selectedAccount} />
          </div>
        </div>
      </div>

      <Cards
        filterIssues={(type) => handleSeverity(type)}
        selectedAccount={selectedAccount}
        inputText={inputText}
        setInputText={setInputText}
      />

      <BlockUi
        tag="div"
        blocking={loading}
        loader={
          <Loader
            active
            type={LOADER_CONFIG.type}
            color={LOADER_CONFIG.color}
          />
        }
      >
        <div id="datastores">
          <div className="table-main">
            <div class="row">
              <div class="col col-xl-4 align-self-start">
                <form onSubmit={makeSearch}>
                  <div class="form-group has-search">
                    <i class="ri-search-line form-control-feedback"></i>
                    <input
                      type="text"
                      className="form-control ml-3"
                      placeholder="Search.."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </form>
              </div>

              <div class="col col-xl-8 align-self-end">
                <div class="p-2 text-right">
                  <Pagination
                    page={page}
                    setPage={(val) => setPage(val)}
                    limit={limit}
                    totalCount={totalDatastoresCount}
                  />
                </div>
              </div>
            </div>

            {currentPosts.length ? (
              <Table
                severity={severity}
                currentPosts={currentPosts}
                sortData={sortData}
                page={"datastore-listing"}
              />
            ) : !loading ? (
              <NoDataContainer message={"No Datastores Available"} />
            ) : (
              ""
            )}
            {/* <WorldMap /> */}
          </div>
        </div>
      </BlockUi>
    </div>
  );
};

export default Datastore;
