import "../Home.scss";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import HackerIcon from "../../../assets/img/icons/hacker-icon.svg";
import activeIcon from "../../../assets/img/icons/small/active.svg";
import Bug from "../../../components/charts/data-store/images/bug.svg";
import AttackIcon from "../../../assets/img/icons/attack-icon.svg";
import LivenessIcon from "../../../assets/img/icons/liveness-icon.svg";
import LockIcon from "../../../assets/img/icons/lock-icon.svg";
import awsLogo from "../../../assets/img/aws-logo.png";
import moment from "moment";

import {
  datastoreIcons,
  formatLine,
  getScoreBorder,
  getScoreColor,
} from "../../../utils/styles";
import infoFilled from "../../../assets/img/icons/ep-info-filled.svg";
import { INFO_MODAL_DATA } from "../../../components/modal/info-modal/InfoModalData";
import { useEffect } from "react";

const BarPopup = ({ datastore, bar, risktype, tab, loading, selectedBar }) => {
  useEffect(() => {
    console.log(bar, "data");
  }, [bar]);
  return (
    <div className="popup-body p-3">
      <div className="title">Details</div>
      <hr></hr>
      <div className="row mt-3">
        <div className="col-md-2 col-lg-2">
          {" "}
          <img
            src={tab === 1 ? datastoreIcons[datastore.type] : Bug}
            style={tab === 1 ? { width: "24px" } : { width: "24px" }}
          />
        </div>
        <div className="col-md-10 col-lg-10 p-0">
          <h5 className="title-1" title={bar.name}>
            {bar.name}
          </h5>
          <h6 className="title-2 mt-2" title={bar.type}>
            <img src={awsLogo} style={{ width: "40px" }} className="mr-2 " />
            {formatLine(bar.type, 40)}
          </h6>
          <div className="row mt-2">
            <div className="col-md-6 col-lg-6 col-sm-6 subtitle-1 ">
              Account:
            </div>
            <div className="col-md-6 col-lg-6 col-sm-6 subtitle-2 p-0">
              {bar["metadata.cloudAccountId"]}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6 col-lg-6 col-sm-6 subtitle-1 ">
              Discovered On:
            </div>
            <div className="col-md-6 col-lg-6 col-sm-6 subtitle-2 p-0">
              {moment(bar["metadata.createdAt"]).format("YYYY-MM-DD")}
            </div>
          </div>{" "}
          {bar?.region && (
            <div className="row mt-2">
              <div className="col-md-6 col-lg-6 col-sm-6 subtitle-1 ">
                Region:
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 subtitle-2 p-0">
                {bar?.region}
              </div>
            </div>
          )}
          <div className="row mt-2">
            <div className="col-md-6 col-lg-6 col-sm-6 subtitle-1">Size:</div>
            <div className="col-md-6 col-lg-6 col-sm-6 subtitle-2 p-0">
              {bar?.size}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarPopup;
