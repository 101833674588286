import { useEffect, useState } from "react";
import "../Home.scss";
import { achievements } from "./data";
import { useDispatch } from "react-redux";
import { getCustomerId } from "../../../utils/SessionHelper";
import { fetchAchievementStats } from "../../../redux/slices/dashboardSlice";

const Achievements = ({ selectedAccount }) => {
  const dispatch = useDispatch();
  const [achievementData, setAchievementData] = useState([...achievements]);

  const updateAchievements = (stats) => {
    const tempStatData = [...achievementData];
    if (stats) {
      for (let index = 0; index < tempStatData.length; index++) {
        let statObj = tempStatData[index];
        if (statObj) {
          statObj.value = stats[statObj.id] || 0;
        }
      }
    }
    setAchievementData([...tempStatData]);
  };
  const getAchievements = async (cloudAccountId) => {
    const payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
    };
    let resp = await dispatch(fetchAchievementStats(payload));
    if (resp && resp.payload && !resp.payload.err) {
      updateAchievements(resp.payload);
    }
  };

  useEffect(() => {
    getAchievements(selectedAccount);
  }, [selectedAccount]);

  return (
    <div id="achievements-layout">
      <div className="row">
        {achievementData &&
          achievementData.map((achievement, index) => {
            return (
              <div
                className="col-md-6 col-lg-6 col-sm-12 p-0"
                key={`achievement${index}`}
              >
                <div className="achievement-card m-3">
                  <div className="achievement-value">{achievement.value}</div>
                  <div className="achievement-title mt-2">
                    {achievement.title}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Achievements;
