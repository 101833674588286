import "../Home.scss";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { LOADER_CONFIG } from "../../../utils/Constants";
import { useEffect, useState } from "react";
import HackerIcon from "../../../assets/img/icons/hacker-icon.svg";
import {
  fetchDataFlowsSlice,
  fetchDataFlowsNewSlice,
} from "../../../redux/slices/dashboardSlice";
import {
  getCustomerId,
  setSelectedDataFlow,
} from "../../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { isJsonEmpty } from "../../../utils/utils";
import { useSelector } from "react-redux";

const DataFlows = ({ firstAccountStatus, selectedAccount, filter }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [dataFlows, setDataFlows] = useState(null);
  const [currentFlows, setCurrentFlows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(filter);
  const expandedSidebar = useSelector((state) => state.dashboard.sidebar);

  useEffect(() => {
    setLoading(true);
    getDataNewFlows();
    const intervalCall = setInterval(() => {
      getDataNewFlows();
    }, 30000);
    return () => {
      if (intervalCall) {
        clearInterval(intervalCall);
      }
    };
  }, [selectedAccount]);

  // useEffect(() => {
  //   if (dataFlows && selectedFilter) {
  //     setCurrentFlowsData(selectedFilter);
  //   }
  // }, [selectedFilter]);

  useEffect(() => {
    if (dataFlows && selectedFilter) {
      setCurrentFlowsData(selectedFilter);
    } else {
      setCurrentFlows([]);
    }
  }, [dataFlows, selectedFilter]);

  const getDataFlows = async () => {
    setLoading(true);
    const payload = {
      customerId: getCustomerId(),
      filters: selectedFilter,
    };
    let res = await dispatch(fetchDataFlowsSlice(payload));
    if (res && !res.err && res.payload) {
      setDataFlows(res.payload);
      setLoading(false);
    } else {
      setLoading(false);
      setDataFlows([]);
    }
  };

  const setCurrentFlowsData = (selectedFilter) => {
    switch (selectedFilter) {
      case "all":
        setCurrentFlows(dataFlows?.flows?.all);
        break;
      case "high-risk":
        setCurrentFlows(dataFlows.flows?.highRisk);
        break;
      case "non-compliant":
        setCurrentFlows(dataFlows.flows?.nonCompliant);
        break;
      case "active-anomaly":
        setCurrentFlows(dataFlows.flows?.dataAnomaly);
        break;
      default:
        setCurrentFlows([]);
        break;
    }
  };

  const getDataNewFlows = async () => {
    const payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
      filters: filter,
    };
    let res = await dispatch(fetchDataFlowsNewSlice(payload));
    if (res && !res.err && res.payload) {
      setDataFlows(res.payload);
      setLoading(false);
    } else {
      setLoading(false);
      setDataFlows([]);
    }
  };

  const rowClickHandler = (dataflow, index) => {
    if (firstAccountStatus?.first_acct_risk_complete) {
      setSelectedDataFlow(dataflow);

      history.push(
        `/datastore-details/${dataflow?.datastore?.id?.replaceAll(
          "/",
          "_"
        )}/${dataflow?.datastore?.assetId?.replaceAll("/", "_")}`
      );
    }
  };

  return (
    <BlockUi
      tag="div"
      id="dataflows-layout"
      blocking={loading}
      loader={
        <Loader active type={LOADER_CONFIG.type} color={LOADER_CONFIG.color} />
      }
    >
      {/* <div id="dataflows-layout"> */}
      {/* <ul class="nav nav-pills mb-1" id="pills-tab" role="tablist">
        <li class="nav-item" onClick={() => setSelectedFilter("all")}>
          <a
            class="nav-link active"
            id="all-tab"
            data-toggle="pill"
            href="#all"
            role="tab"
            aria-selected="false"
          >
            Recently Accessed
          </a>
        </li>
        <li class="nav-item" onClick={() => setSelectedFilter("high-risk")}>
          <a
            class="nav-link"
            id="risk-tab"
            data-toggle="pill"
            href="#risk"
            role="tab"
            aria-controls="high-risk"
            aria-selected="false"
          >
            {dataFlows?.highRisk && (
              <img src={HackerIcon} width="16px" className="hacker-icon" />
            )}
            Risk
          </a>
        </li>{" "}
        <li class="nav-item" onClick={() => setSelectedFilter("non-compliant")}>
          <a
            class="nav-link"
            id="non-compliant"
            data-toggle="pill"
            href="#non-compliant"
            role="tab"
            aria-controls="non-compliant"
            aria-selected="false"
          >
            Policy Violation
          </a>
        </li>
        <li
          class="nav-item"
          onClick={() => setSelectedFilter("active-anomaly")}
        >
          <a
            class="nav-link"
            id="active-anomaly"
            data-toggle="pill"
            href="#active-anomaly"
            role="tab"
            aria-controls="active-anomaly"
            aria-selected="false"
          >
            {dataFlows?.dataAnomaly && (
              <img src={HackerIcon} width="16px" className="hacker-icon" />
            )}
            Data Anomaly
          </a>
        </li>
      </ul> */}
      {filter === "high-risk" ? (
        <div className="sub-title mt-4">Data Flows</div>
      ) : (
        <div className="sub-title mt-4">Data Flows</div>
      )}

      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="all"
          role="tabpanel"
          aria-labelledby="high-risk-tab"
        >
          <div className="mt-4 table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Source</th>
                  <th>Time</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!isJsonEmpty(currentFlows) &&
                  currentFlows &&
                  currentFlows.map((dataFlow, index) => {
                    return (
                      <tr
                        key={`dataFlow${index}`}
                        onClick={() => rowClickHandler(dataFlow, index)}
                        className={`${
                          firstAccountStatus?.first_acct_risk_complete
                            ? "cursor-pointer"
                            : "cursor-default"
                        }`}
                      >
                        <td title={dataFlow?.datastore?.name}>
                          {dataFlow?.datastore?.name?.length > 17
                            ? dataFlow?.datastore?.name.slice(0, 17) + "..."
                            : dataFlow?.datastore?.name || ""}
                        </td>
                        <td title={dataFlow?.source?.name}>
                          {dataFlow?.source?.name?.length > 15
                            ? dataFlow?.source?.name
                                ?.replace("instance/", "")
                                ?.slice(0, expandedSidebar ? 12 : 17) + "..."
                            : dataFlow?.source?.name?.replace(
                                "instance/",
                                ""
                              ) || ""}
                        </td>
                        <td>
                          {dataFlow?.timestamp
                            ? moment(moment.utc(dataFlow?.timestamp).toDate())
                                .local()
                                .fromNow()
                            : "N/A"}
                        </td>
                        <td>
                          {dataFlow?.source.attackStatus && (
                            <img
                              src={HackerIcon}
                              width="20px"
                              // className="hacker-icon"
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* </div> */}
    </BlockUi>
  );
};

export default DataFlows;
