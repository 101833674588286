import "./Home.scss";
import Card from "../shared/Card";
import React, { useEffect, useState } from "react";
import InfoModal from "../../components/modal/info-modal/InfoModal";
import DataStores from "../../components/home/datastores/Datastores";
import DataFlows from "../../components/home/data-flows/DataFlow";
import SecurityIssues from "../../components/home/security-issues/SecurityIssues";
import Achievements from "../../components/home/achievements/Achievements";
import ImpactedDatastores from "../../components/home/impacted-datastores/ImpactedDatastores";
import Trend from "../../components/home/trends/Trends";
import { getDatastoresByFilterApi } from "../../apis";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerId } from "../../utils/SessionHelper";
import { addIndex, colorRiskData } from "../../utils/dataHandler";
import {
  firstAccountStatStatus,
  listRiskPlot,
} from "../../redux/slices/dashboardSlice";
import { useHistory } from "react-router-dom";
import DashboardStats from "./dashboard-stats/DashboardStats";
import { isJsonEmpty, numberFormatter } from "../../utils/utils";
import FirstAccountStats from "./dashboard-stats/FirstAccountStats";
import FirstTimeLoginAccount from "../cards/account/FirstTimeLoginAccount";

const Home = ({ setPath, tab, selectedAccount, isIssueViewVisible }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const limit = 10;
  const [risktype, setRisktype] = useState("high");
  const [severity, setSeverity] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [page, setPage] = useState(1);
  const [bars, setBars] = useState([]);
  const [selectedBar, setSelectedBar] = useState({});
  const [isBarClicked, setIsBarClicked] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(10);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState(false);
  const [dataStores, setDatastores] = useState([]);
  const [issues, setIssues] = useState([]);
  const [dataRisks, setDataRisks] = useState([]);
  const [totalDatastoresCount, setTotalDatastoresCount] = useState(0);
  const [issuesCount, setIssuesCount] = useState(0);
  const [datastoreIds, setDatastoreIds] = useState([]);
  const [hoveredDatastoreIds, setHoveredDatastoreIds] = useState([]);
  const [datastore, setDatastore] = useState({});
  const [loading, setLoading] = useState(false);
  const [datastoresLoading, setDatastoresLoading] = useState(false);
  const [riskplotLoading, setRiskplotLoading] = useState(false);
  const [filteredDatastores, setFiltersDatastores] = useState([]);
  const [filteredPolicyDatastores, setPolicyFiltersDatastores] = useState([]);

  const riskPlot = useSelector((state) => state.dashboard.riskPlot);
  const [breachCost, setBreachCost] = useState(0);

  const firstAccountStatus = useSelector(
    (state) => state.dashboard.firstAccountStatus
  );
  const accountAvailable = useSelector(
    (state) => state.account.accountAvailable
  );

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  // const getDataStores = async () => {
  //   setDatastoresLoading(true);
  //   const payload = {
  //     customerId: getCustomerId(),
  //     limit: limit,
  //     page: page,
  //     datastoreIds: datastoreIds,
  //     severity:
  //       !severity || severity.includes("TOTAL DATA STORES")
  //         ? null
  //         : severity.replaceAll(" ", "_"),
  //   };
  //   let res = await getDataStoresApi(payload);
  //   if (!res.data) return;
  //   setTotalDatastoresCount(res.count);
  //   res = addIndex(res.data);
  //   // res[0].numSecurityIssues = 100;
  //   // res[0].numAttackPath = 100;
  //   setDatastores(res);
  //   setFiltersDatastores(res);
  //   if (res.length) {
  //     setSelectedBar(res[0]);
  //   }
  //   setDatastoresLoading(false);
  // };

  const getFirstAccountStats = () => {
    const payload = {
      customerId: getCustomerId(),
    };

    dispatch(firstAccountStatStatus(payload));
  };

  const getDatastoresByFilter = async () => {
    setDatastoresLoading(true);
    const payload = {
      customerId: getCustomerId(),
      limit: limit,
      page: page,
      datastoreIds: datastoreIds,
      filters: "all",
      limit: 10,
      page: 1,
      severity:
        !severity || severity.includes("TOTAL DATA STORES")
          ? null
          : severity.replaceAll(" ", "_"),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
    };
    let res = await getDatastoresByFilterApi(payload);
    if (res.err) {
      setFiltersDatastores([]);
      setDatastoresLoading(false);
    } else {
      if (res.data) {
        setFiltersDatastores(res.data);
      } else {
        setFiltersDatastores(res);
      }

      if (res.length) {
        setSelectedBar(res[0]);
      }
      setDatastoresLoading(false);
    }
  };

  const getDatastoresByPolicyFilter = async () => {
    setDatastoresLoading(true);
    const payload = {
      customerId: getCustomerId(),
      limit: limit,
      page: page,
      datastoreIds: datastoreIds,
      filters: "non-compliant",
      limit: 10,
      page: 1,
      severity:
        !severity || severity.includes("TOTAL DATA STORES")
          ? null
          : severity.replaceAll(" ", "_"),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
    };
    let res = await getDatastoresByFilterApi(payload);
    if (res.err) {
      setFiltersDatastores([]);
      setDatastoresLoading(false);
    } else {
      if (res.data) {
        setPolicyFiltersDatastores(res.data);
      } else {
        setPolicyFiltersDatastores(res);
      }
      if (res.length) {
        setSelectedBar(res[0]);
      }
      setDatastoresLoading(false);
    }
  };

  const filterBars = (ids) => {
    setDatastoreIds(ids);
  };

  useEffect(() => {
    if (riskPlot) {
      let data = riskPlot;
      if (!data || isJsonEmpty(data)) return;
      data = colorRiskData(addIndex(data));
      setDataRisks(data);
    }
  }, [riskPlot]);

  // const getIssues = async () => {
  //   setDatastoresLoading(true);
  //   const payload = {
  //     customerId: getCustomerId(),
  //     limit: limit,
  //     page: page,
  //     severity:
  //       !severity || severity.includes("TOTAL ISSUES")
  //         ? null
  //         : severity.replaceAll(" ", "_"),
  //   };
  //   let res = await getIssuesApi(payload);
  //   if (!res.data) return;
  //   setIssuesCount(res.count);
  //   res = addIndex(res.data);
  //   setIssues(res);
  //   if (res.length) {
  //     setSelectedBar(res[0]);
  //   }
  //   setDatastoresLoading(false);
  // };

  const getDataRiskPlot = () => {
    setRiskplotLoading(true);
    const payload = {
      customerId: getCustomerId(),
      severity:
        !severity || severity.includes("TOTAL DATA STORES")
          ? null
          : severity.replaceAll(" ", "_"),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
    };
    dispatch(listRiskPlot(payload));
    setRiskplotLoading(false);
  };

  const vulDatastores = () => {
    let vunerabilityDataStores_ = issues;
    if (severity) {
      vunerabilityDataStores_ = issues.filter((i) => severity === i.severity);
    }
    const data = vunerabilityDataStores_.slice(start - 1, end);
    return data;
  };

  const datastores = () => {
    let data_ = dataStores;
    if (severity) {
      data_ = dataStores.filter((i) => severity === i.severity);
    }
    const data = data_.slice(start - 1, end);
    return data;
  };

  const handleBarData = () => {
    const data = tab === 2 ? vulDatastores() : datastores();
    setBars(data);
    sessionStorage.setItem("selected_bar", JSON.stringify(data[0]));
  };

  const handleInfoModal = (visible, data) => {
    setInfoModalData(data);
    setShowInfoModal(visible);
  };

  useEffect(() => {
    getDatastoresByFilter();
    getDatastoresByPolicyFilter();
    // if (
    //   selectedFilter === "non-compliant" ||
    //   selectedFilter === "active-anomaly"
    // ) {
    //   getDatastoresByFilter();
    // } else {
    //   getDataStores();
    // }
  }, [selectedFilter, selectedAccount]);

  useEffect(() => {
    handleBarData();
  }, [page, risktype]);

  useEffect(() => {
    setStart(1);
    setEnd(10);
    setPage(1);
    handleBarData();
  }, [severity]);

  useEffect(() => {
    setSeverity(null);
    setStart(1);
    setEnd(10);
    setPage(1);

    handleBarData();
  }, [tab]);

  useEffect(() => {
    getDataRiskPlot();

    // if (tab === 1) {
    //   getDatastoresByFilter();
    //   // getDataStores();
    // } else {
    //   getIssues();
    // }
  }, [page, tab, selectedAccount, severity, datastoreIds]);

  useEffect(() => {
    setPath(window.location.pathname);
    document.title = "Cloud Defense - Dashboard";
    getFirstAccountStats();
  }, []);

  return (
    <div>
      {accountAvailable === false ? (
        <FirstTimeLoginAccount />
      ) : (
        <div id="home-layout">
          <div>
            <div className="pt-3 pr-3">
              {firstAccountStatus?._id && accountAvailable ? (
                <div>
                  {firstAccountStatus &&
                  !firstAccountStatus?.first_acct_risk_complete ? (
                    <FirstAccountStats
                      firstAccountStatus={firstAccountStatus}
                    />
                  ) : (
                    <DashboardStats selectedAccount={selectedAccount} />
                  )}
                </div>
              ) : (
                <div></div>
              )}
              {isIssueViewVisible ? (
                <div className="row mb-4">
                  <div class="col-lg-12 col-md-12 col-sm-12 ">
                    <Card>
                      <div className="row">
                        {" "}
                        <div class="col-lg-6 col-md-12">
                          <Card custom={"p-0"} title="Security Issues">
                            <SecurityIssues
                              selectedAccount={selectedAccount}
                              setHoveredDatastoreIds={setHoveredDatastoreIds}
                              setBreachCost={setBreachCost}
                              firstAccountStatus={firstAccountStatus}
                            />
                          </Card>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <Card
                            custom={"p-0"}
                            title="Impacted Data Stores"
                            subTitleComp={
                              breachCost && (
                                <div className="sub-title float-right text-right">
                                  Estimated Darknet Value
                                  <div className="breach-cost ml-2">
                                    ${numberFormatter(breachCost || 0)}
                                  </div>
                                </div>
                              )
                            }
                          >
                            <ImpactedDatastores
                              bars={tab === 1 ? dataStores : issues}
                              hoveredDatastoreIds={hoveredDatastoreIds}
                              setRisktype={setRisktype}
                              selectedBar={selectedBar}
                              vunerability={tab === 2}
                              risktype={risktype}
                              data={dataRisks}
                              severity={severity}
                              handleInfoModal={handleInfoModal}
                              filterBars={filterBars}
                              loading={riskplotLoading}
                            />
                          </Card>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              ) : (
                <div className="row mb-4">
                  <div class="col-lg-6 col-md-12">
                    <Card title="Risk">
                      <div>
                        <DataStores
                          filter="all"
                          datastore={datastore}
                          page={page}
                          vunerability={tab === 2}
                          risktype={risktype}
                          setBars={setBars}
                          selectedBar={selectedBar}
                          setSelectedBar={setSelectedBar}
                          setIsBarClicked={setIsBarClicked}
                          datastores={tab === 1 ? filteredDatastores : issues}
                          severity={severity}
                          nextPage={nextPage}
                          prevPage={prevPage}
                          isBarClicked={isBarClicked}
                          start={start}
                          end={end}
                          tab={tab}
                          limit={limit}
                          totalDatastoresCount={
                            tab === 1 ? totalDatastoresCount : issuesCount
                          }
                          handleInfoModal={handleInfoModal}
                          loading={datastoresLoading}
                          setSelectedFilter={setSelectedFilter}
                          firstAccountStatus={firstAccountStatus}
                        />
                      </div>
                      <div className="mt-2">
                        <DataFlows
                          filter="high-risk"
                          firstAccountStatus={firstAccountStatus}
                          selectedAccount={selectedAccount}
                        />
                      </div>
                    </Card>
                  </div>
                  <div class="col-lg-6 col-md-12 ">
                    <Card title="Policy">
                      <DataStores
                        filter="non-compliant"
                        datastore={datastore}
                        page={page}
                        vunerability={tab === 2}
                        risktype={risktype}
                        setBars={setBars}
                        selectedBar={selectedBar}
                        setSelectedBar={setSelectedBar}
                        setIsBarClicked={setIsBarClicked}
                        datastores={
                          tab === 1 ? filteredPolicyDatastores : issues
                        }
                        severity={severity}
                        nextPage={nextPage}
                        prevPage={prevPage}
                        isBarClicked={isBarClicked}
                        start={start}
                        end={end}
                        tab={tab}
                        limit={limit}
                        totalDatastoresCount={
                          tab === 1 ? totalDatastoresCount : issuesCount
                        }
                        handleInfoModal={handleInfoModal}
                        loading={datastoresLoading}
                        setSelectedFilter={setSelectedFilter}
                        firstAccountStatus={firstAccountStatus}
                      />
                      <DataFlows
                        filter="non-compliant"
                        firstAccountStatus={firstAccountStatus}
                        selectedAccount={selectedAccount}
                      />
                    </Card>
                  </div>
                </div>
              )}
              {isIssueViewVisible ? (
                <div className="row mb-4">
                  <div class="col-lg-6 col-md-12">
                    <Card title="Risk">
                      <div>
                        <DataStores
                          filter="all"
                          datastore={datastore}
                          page={page}
                          vunerability={tab === 2}
                          risktype={risktype}
                          setBars={setBars}
                          selectedBar={selectedBar}
                          setSelectedBar={setSelectedBar}
                          setIsBarClicked={setIsBarClicked}
                          datastores={tab === 1 ? filteredDatastores : issues}
                          severity={severity}
                          nextPage={nextPage}
                          prevPage={prevPage}
                          isBarClicked={isBarClicked}
                          start={start}
                          end={end}
                          tab={tab}
                          limit={limit}
                          totalDatastoresCount={
                            tab === 1 ? totalDatastoresCount : issuesCount
                          }
                          handleInfoModal={handleInfoModal}
                          loading={datastoresLoading}
                          setSelectedFilter={setSelectedFilter}
                          firstAccountStatus={firstAccountStatus}
                        />
                      </div>
                      <div className="mt-2">
                        <DataFlows
                          filter="high-risk"
                          firstAccountStatus={firstAccountStatus}
                          selectedAccount={selectedAccount}
                        />
                      </div>
                    </Card>
                  </div>
                  <div class="col-lg-6 col-md-12 ">
                    <Card title="Policy">
                      <DataStores
                        filter="non-compliant"
                        datastore={datastore}
                        page={page}
                        vunerability={tab === 2}
                        risktype={risktype}
                        setBars={setBars}
                        selectedBar={selectedBar}
                        setSelectedBar={setSelectedBar}
                        setIsBarClicked={setIsBarClicked}
                        datastores={
                          tab === 1 ? filteredPolicyDatastores : issues
                        }
                        severity={severity}
                        nextPage={nextPage}
                        prevPage={prevPage}
                        isBarClicked={isBarClicked}
                        start={start}
                        end={end}
                        tab={tab}
                        limit={limit}
                        totalDatastoresCount={
                          tab === 1 ? totalDatastoresCount : issuesCount
                        }
                        handleInfoModal={handleInfoModal}
                        loading={datastoresLoading}
                        setSelectedFilter={setSelectedFilter}
                        firstAccountStatus={firstAccountStatus}
                      />
                      <DataFlows
                        filter="non-compliant"
                        firstAccountStatus={firstAccountStatus}
                        selectedAccount={selectedAccount}
                      />
                    </Card>
                  </div>
                </div>
              ) : (
                <div className="row mb-4">
                  <div class="col-lg-12 col-md-12 col-sm-12 ">
                    <Card>
                      <div className="row">
                        {" "}
                        <div class="col-lg-6 col-md-12">
                          <Card custom={"p-0"} title="Security Issues">
                            <SecurityIssues
                              selectedAccount={selectedAccount}
                              setHoveredDatastoreIds={setHoveredDatastoreIds}
                              setBreachCost={setBreachCost}
                              firstAccountStatus={firstAccountStatus}
                            />
                          </Card>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <Card
                            custom={"p-0"}
                            title="Impacted Data Stores"
                            subTitleComp={
                              breachCost && (
                                <div className="sub-title float-right text-right">
                                  Estimated Darknet Value
                                  <div className="breach-cost ml-2">
                                    ${numberFormatter(breachCost || 0)}
                                  </div>
                                </div>
                              )
                            }
                          >
                            <ImpactedDatastores
                              bars={tab === 1 ? dataStores : issues}
                              hoveredDatastoreIds={hoveredDatastoreIds}
                              setRisktype={setRisktype}
                              selectedBar={selectedBar}
                              vunerability={tab === 2}
                              risktype={risktype}
                              data={dataRisks}
                              severity={severity}
                              handleInfoModal={handleInfoModal}
                              filterBars={filterBars}
                              loading={riskplotLoading}
                            />
                          </Card>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              )}

              <div className="row mb-2 ">
                <div class="col-lg-6 col-md-12">
                  <Card title="Trends">
                    <Trend selectedAccount={selectedAccount} />
                  </Card>
                </div>{" "}
                <div class="col-lg-6 col-md-12 ">
                  <Card title="Achievements">
                    <Achievements selectedAccount={selectedAccount} />
                  </Card>
                </div>
              </div>
            </div>
            {showInfoModal && (
              <InfoModal
                handleModal={() => setShowInfoModal(false)}
                data={infoModalData}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
