export const achievements = [
  {
    id: "deRiskedData",
    title: "Data stores de-risked",
    value: "0",
  },
  {
    id: "shadowData",
    title: "Shadow data secured",
    value: "0",
  },
  {
    id: "resolvedIssued",
    title: "Resolved issues",
    value: "0",
  },
  {
    id: "policiesTags",
    title: "Policy tags added",
    value: "0",
  },
];
