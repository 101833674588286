import { axiosPrivate } from "../../utils/ApiHandler";

/**
 * Provides the list of all accounts.
 * @returns -- Accounts list
 */
export const fetchIntegrations = (user_id) => {
  return axiosPrivate.get(`/user/getAllIntegrations/${user_id}`);
};

/**
 * Provides the list of all accounts.
 * @returns -- Octa list
 */
export const fetchOktaIntegrations = (user_id) => {
  return axiosPrivate.get(`/user/cognitoOktaIntegration/${user_id}`);
};

/**
 * Provides the list of all accounts.
 * @returns -- Octa list
 */
export const fetchSlackIntegrations = () => {
  return axiosPrivate.get(`/slack/connectSlack`);
};

/**
 * Add the accounts with the specified account details.
 * @param {object} params -- payload params
 * @returns the account details added.
 */
export const addIntegration = (params) => {
  return axiosPrivate.post(`/jira/saasConnector`, params);
};

export const updateIntegration = (id, params) => {
  return axiosPrivate.patch(`/jira/saasConnector/${id}`, params);
};

export const updateSlackIntegration = (id, params) => {
  return axiosPrivate.patch(`/slack/connectSlack/${id}`, params);
};

export const updateWizIntegration = (id, params) => {
  return axiosPrivate.patch(`/api/v1/integrations/cnapp/wiz/${id}`, params);
};

/**
 * Delete the account with the specified account Id.
 * @param {object} accountId -- account Id to be deleted
 */
export const deleteIntegration = (integrationId) => {
  return axiosPrivate.delete(`/jira/saasConnector/${integrationId}`);
};

/**
 * Delete the account with the specified account Id.
 * @param {object} accountId -- account Id to be deleted
 */
export const deleteOktaIntegration = (integrationId) => {
  return axiosPrivate.delete(`/user/cognitoOktaIntegration/${integrationId}`);
};

/**
 * Delete the account with the specified account Id.
 * @param {object} accountId -- account Id to be deleted
 */
export const deleteSlackIntegration = (integrationId) => {
  return axiosPrivate.delete(`/slack/connectSlack/${integrationId}`);
};

export const deleteWizIntegration = (integrationId) => {
  return axiosPrivate.delete(`/api/v1/integrations/cnapp/wiz/${integrationId}`);
};

/**
 *
 * Add the okta accounts with the specified account details.
 * @param {object} params -- payload params
 * @returns the okta account details added.
 */
export const addOktaIntegration = (params) => {
  return axiosPrivate.post(`/user/cognitoOktaIntegration`, params);
};

/**
 *
 * Add the okta accounts with the specified account details.
 * @param {object} params -- payload params
 * @returns the okta account details added.
 */
export const addSlackIntegration = (params) => {
  return axiosPrivate.post(`/slack/connectSlack`, params);
};

export const addWizIntegration = (params) => {
  return axiosPrivate.post(`/api/v1/integrations/cnapp/wiz`, params);
};
