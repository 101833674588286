import mysqlScoreLow from "../assets/img/icons/score-icons/ico-mysql-score-low.svg";
import mysqlScoreHigh from "../assets/img/icons/score-icons/ico-mysql-score-high.svg";
import mysqlScoreModerate from "../assets/img/icons/score-icons/ico-mysql-score-moderate.svg";
import mysqlScoreElevated from "../assets/img/icons/score-icons/ico-mysql-score-elevated.svg";

import docDbScoreLow from "../assets/img/icons/score-icons/ico-doc-db-score-low.svg";
import docDbScoreHigh from "../assets/img/icons/score-icons/ico-doc-db-score-high.svg";
import docDbScoreModerate from "../assets/img/icons/score-icons/ico-doc-db-score-elevated.svg";
import docDbScoreElevated from "../assets/img/icons/score-icons/ico-doc-db-score-elevated.svg";

import ecScoreLow from "../assets/img/icons/score-icons/ico-elastic-cache-score-low.svg";
import edScoreHigh from "../assets/img/icons/score-icons/ico-elastic-cache-score-high.svg";
import ecScoreModerate from "../assets/img/icons/score-icons/ico-elastic-cache-score-moderate.svg";
import ecScoreElevated from "../assets/img/icons/score-icons/ico-elastic-cache-score-elevated.svg";

import mongodbScoreLow from "../assets/img/icons/score-icons/ico-mongodb-score-low.svg";
import mongodbScoreHigh from "../assets/img/icons/score-icons/ico-mongodb-score-high.svg";
import mongodbScoreModerate from "../assets/img/icons/score-icons/ico-mongodb-score-moderate.svg";
import mongodbScoreElevated from "../assets/img/icons/score-icons/ico-mongodb-score-elevated.svg";

import neptuneScoreLow from "../assets/img/icons/score-icons/ico-neptune-db-score-low.svg";
import neptuneScoreHigh from "../assets/img/icons/score-icons/ico-neptune-db-score-high.svg";
import neptuneScoreModerate from "../assets/img/icons/score-icons/ico-neptune-db-score-moderate.svg";
import neptuneScoreElevated from "../assets/img/icons/score-icons/ico-neptune-db-score-elevated.svg";

import postgressScoreLow from "../assets/img/icons/score-icons/ico-postgress-score-low.svg";
import postgressScoreHigh from "../assets/img/icons/score-icons/ico-postgress-score-high.svg";
import postgressScoreModerate from "../assets/img/icons/score-icons/ico-postgress-score-moderate.svg";
import postgressScoreElevated from "../assets/img/icons/score-icons/ico-postgress-score-elevated.svg";

import rdsScoreLow from "../assets/img/icons/score-icons/ico-rds-score-low.svg";
import rdsScoreHigh from "../assets/img/icons/score-icons/ico-rds-score-high.svg";
import rdsScoreModerate from "../assets/img/icons/score-icons/ico-rds-score-moderate.svg";
import rdsScoreElevated from "../assets/img/icons/score-icons/ico-rds-score-elevated.svg";

import s3ScoreLow from "../assets/img/icons/score-icons/ico-s3-score-low.svg";
import s3ScoreHigh from "../assets/img/icons/score-icons/ico-s3-score-high.svg";
import s3ScoreModerate from "../assets/img/icons/score-icons/ico-s3-score-moderate.svg";
import s3ScoreElevated from "../assets/img/icons/score-icons/ico-s3-score-elevated.svg";

import bugScoreLow from "../assets/img/icons/score-icons/ico-bug-score-low.svg";
import bugScoreHigh from "../assets/img/icons/score-icons/ico-bug-score-high.svg";
import bugScoreModerate from "../assets/img/icons/score-icons/ico-bug-score-moderate.svg";
import bugScoreElevated from "../assets/img/icons/score-icons/ico-bug-score-elevated.svg";

import gDbScoreLow from "../assets/img/icons/score-icons/ico-generic-db-score-low.svg";
import gDbScoreHigh from "../assets/img/icons/score-icons/ico-generic-db-score-high.svg";
import gDbScoreModerate from "../assets/img/icons/score-icons/ico-generic-db-score-moderate.svg";
import gDbScoreElevated from "../assets/img/icons/score-icons/ico-generic-db-score-elevated.svg";

import redShiftScoreLow from "../assets/img/icons/score-icons/ico-aws-red-shift-score-low.svg";
import redShiftScoreHigh from "../assets/img/icons/score-icons/ico-aws-red-shift-score-high.svg";
import redShiftScoreModerate from "../assets/img/icons/score-icons/ico-aws-red-shift-score-moderate.svg";
import redShiftScoreElevated from "../assets/img/icons/score-icons/ico-aws-red-shift-score-elevated.svg";

//new icons

import { ReactComponent as RdsIcon } from "../assets/img/new-theme/datastore-icons/ico-rds-score-low.svg";
import { ReactComponent as MySqlIcon } from "../assets/img/new-theme/datastore-icons/ico-mysql-score-low.svg";
import { ReactComponent as DocDBIcon } from "../assets/img/new-theme/datastore-icons/ico-doc-db-score-low.svg";
import { ReactComponent as ECIcon } from "../assets/img/new-theme/datastore-icons/ico-elastic-cache-score-low.svg";
import { ReactComponent as MongoDBIcon } from "../assets/img/new-theme/datastore-icons/ico-mongodb-score-low.svg";
import { ReactComponent as NeptuneIcon } from "../assets/img/new-theme/datastore-icons/ico-neptune-db-score-low.svg";
import { ReactComponent as PostgresDBIcon } from "../assets/img/new-theme/datastore-icons/ico-postgress-score-low.svg";
import { ReactComponent as S3Icon } from "../assets/img/new-theme/datastore-icons/ico-s3-score-low.svg";
import { ReactComponent as RedShiftIcon } from "../assets/img/new-theme/datastore-icons/ico-aws-red-shift-score-low.svg";
import { ReactComponent as GenericDBIcon } from "../assets/img/new-theme/datastore-icons/ico-generic-db-score-low.svg";

import DocDB from "../assets/img/new-theme/DocDB.svg";
import ElasticCache from "../assets/img/new-theme/ElasticCache.svg";
import NeptuneDb from "../assets/img/new-theme/NeptuneDb.svg";
import RedShift from "../assets/img/icons/red-shift.svg";
import Mongodb from "../assets/img/new-theme/MongoDB.svg";
import MYSQL from "../assets/img/new-theme/MYSQL.svg";
import Generic from "../assets/img/new-theme/generic.svg";
import PostgreDB from "../assets/img/new-theme/PostgreDB.svg";
import RDS from "../assets/img/new-theme/RDS.svg";
import S3 from "../assets/img/new-theme/S3.svg";

export const RND_STYLE = {
  alignItems: "center",
  justifyContent: "center",
  background: "#fff",
  boxShadow: "0px 0px 12px 0px rgba(55, 73, 72, 0.06)",
  borderRadius: "10px",
  border: "none",
};

export const getValueInBetween = (num, first, last) => {
  if (num) {
    num = num.toFixed();
  }
  if (num >= first && num <= last) {
    return true;
  } else if (num >= first && !last) {
    return true;
  }
  return false;
};

export const getScoreColor = (num, bg) => {
  if (Array.isArray(num)) {
    return "";
  }
  let color = "";
  if (getValueInBetween(num, 0, 59)) {
    color = "score-low";
  } else if (getValueInBetween(num, 60, 79)) {
    color = "score-moderate";
  } else if (getValueInBetween(num, 80, 89)) {
    color = "score-high";
  } else if (getValueInBetween(num, 90)) {
    color = "score-elevated";
  } else {
    color = "score-low";
  }
  if (bg) {
    color = color + "-level";
  }
  return color;
};

export const getScoreColorCode = (num) => {
  if (Array.isArray(num)) {
    return "";
  }
  let color = "";
  if (getValueInBetween(num, 0, 59)) {
    color = "#3260e5";
  } else if (getValueInBetween(num, 60, 79)) {
    color = "#fecf1c";
  } else if (getValueInBetween(num, 80, 89)) {
    color = "#dc6e30";
  } else if (getValueInBetween(num, 90)) {
    color = "#e32b2a";
  } else {
    color = "#3260e5";
  }
  return color;
};

export const getScoreBorder = (num, bg) => {
  let color = "";
  if (getValueInBetween(num, 0, 59)) {
    color = "leaplabs-low";
  } else if (getValueInBetween(num, 60, 79)) {
    color = "leaplabs-moderate";
  } else if (getValueInBetween(num, 80, 89)) {
    color = "leaplabs-high";
  } else if (getValueInBetween(num, 90)) {
    color = "leaplabs-elevated";
  } else {
    color = "leaplabs-low";
  }
  return color;
};
export const getDatastoreNewIcon = (type, score) => {
  const scoreColor = getScoreColorCode(score);
  const scoreColorClass = getScoreColor(score);
  switch (type) {
    case "mysql":
      return (
        <MySqlIcon className={`${scoreColorClass}-svg`} fill={scoreColor} />
      );
    case "rds":
    case "RDS-Postgres":
      return <RdsIcon className={`${scoreColorClass}-svg`} fill={scoreColor} />;
    case "mongodb":
      return (
        <MongoDBIcon className={`${scoreColorClass}-svg`} fill={scoreColor} />
      );
    case "PostgreSQL":
      return (
        <PostgresDBIcon
          className={`${scoreColorClass}-svg`}
          fill={scoreColor}
        />
      );
    case "s3":
      return <S3Icon className={`${scoreColorClass}-svg`} fill={scoreColor} />;
    case "docdb":
      return (
        <DocDBIcon className={`${scoreColorClass}-svg`} fill={scoreColor} />
      );
    case "neptune":
      return (
        <NeptuneIcon className={`${scoreColorClass}-svg`} fill={scoreColor} />
      );
    case "elasticache":
      return <ECIcon className={`${scoreColorClass}-svg`} fill={scoreColor} />;
    case "redshift":
      return (
        <RedShiftIcon className={`${scoreColorClass}-svg`} fill={scoreColor} />
      );
    default:
      return (
        <GenericDBIcon className={`${scoreColorClass}-svg`} fill={scoreColor} />
      );
  }
};

export const getDatastoreIcon = (type, score) => {
  const scoreColor = getScoreColor(score);
  switch (type) {
    case "mysql":
      switch (scoreColor) {
        case "score-low":
          return mysqlScoreLow;
        case "score-moderate":
          return mysqlScoreModerate;
        case "score-high":
          return mysqlScoreHigh;
        case "score-elevated":
          return mysqlScoreElevated;
        default:
          return mysqlScoreLow;
      }
    case "rds":
    case "RDS-Postgres":
      switch (scoreColor) {
        case "score-low":
          return rdsScoreLow;
        case "score-moderate":
          return rdsScoreModerate;
        case "score-high":
          return rdsScoreHigh;
        case "score-elevated":
          return rdsScoreElevated;
        default:
          return rdsScoreLow;
      }
    case "mongodb":
      switch (scoreColor) {
        case "score-low":
          return mongodbScoreLow;
        case "score-moderate":
          return mongodbScoreModerate;
        case "score-high":
          return mongodbScoreHigh;
        case "score-elevated":
          return mongodbScoreElevated;
        default:
          return mongodbScoreLow;
      }
    case "PostgreSQL":
      switch (scoreColor) {
        case "score-low":
          return postgressScoreLow;
        case "score-moderate":
          return postgressScoreModerate;
        case "score-high":
          return postgressScoreHigh;
        case "score-elevated":
          return postgressScoreElevated;
        default:
          return postgressScoreLow;
      }
    case "s3":
      switch (scoreColor) {
        case "score-low":
          return s3ScoreLow;
        case "score-moderate":
          return s3ScoreModerate;
        case "score-high":
          return s3ScoreHigh;
        case "score-elevated":
          return s3ScoreElevated;
        default:
          return s3ScoreLow;
      }
    case "docdb":
      switch (scoreColor) {
        case "score-low":
          return docDbScoreLow;
        case "score-moderate":
          return docDbScoreModerate;
        case "score-high":
          return docDbScoreHigh;
        case "score-elevated":
          return docDbScoreElevated;
        default:
          return docDbScoreLow;
      }
    case "neptune":
      switch (scoreColor) {
        case "score-low":
          return neptuneScoreLow;
        case "score-moderate":
          return neptuneScoreModerate;
        case "score-high":
          return neptuneScoreHigh;
        case "score-elevated":
          return neptuneScoreElevated;
        default:
          return neptuneScoreLow;
      }
    case "elasticache":
      switch (scoreColor) {
        case "score-low":
          return ecScoreLow;
        case "score-moderate":
          return ecScoreModerate;
        case "score-high":
          return edScoreHigh;
        case "score-elevated":
          return ecScoreElevated;
        default:
          return ecScoreLow;
      }
    case "redshift":
      switch (scoreColor) {
        case "score-low":
          return redShiftScoreLow;
        case "score-moderate":
          return redShiftScoreModerate;
        case "score-high":
          return redShiftScoreHigh;
        case "score-elevated":
          return redShiftScoreElevated;
        default:
          return redShiftScoreLow;
      }
    default:
      switch (scoreColor) {
        case "score-low":
          return gDbScoreLow;
        case "score-moderate":
          return gDbScoreModerate;
        case "score-high":
          return gDbScoreHigh;
        case "score-elevated":
          return gDbScoreElevated;
        default:
          return gDbScoreLow;
      }
  }
};

export const getIssueIcon = (type, score) => {
  const scoreColor = getScoreColor(score);
  // switch (type) {
  //   case "mysql":
  switch (scoreColor) {
    case "score-low":
      return bugScoreLow;
    case "score-moderate":
      return bugScoreModerate;
    case "score-high":
      return bugScoreHigh;
    case "score-elevated":
      return bugScoreElevated;
    default:
      return bugScoreLow;
  }
  //   default:
  //     return null;
  // }
};

export const datastoreIcons = {
  mysql: MYSQL,
  rds: RDS,
  "RDS-Postgres": RDS,
  mongodb: Mongodb,
  PostgreSQL: PostgreDB,
  s3: S3,
  docdb: DocDB,
  neptune: NeptuneDb,
  elasticache: ElasticCache,
  redshift: RedShift,
  generic: Generic,
};

export const formatLine = (content, num) => {
  if (!content) return content;
  return content.length > num ? content.slice(0, num) + "..." : content;
};
