import React, { useEffect, useState } from "react";
import checkCircle from "../../../assets/img/icons/check-circle.svg";
import halfCircleLoader from "../../../assets/img/half-circle-loader.svg";

const FirstAccountStatsSteps = ({
  title,
  score,
  scoreClass,
  index,
  cardDisable,
  stepCompleted,
  id,
}) => {
  return (
    <>
      <div
        class={
          !cardDisable && id === "highRiskStore"
            ? "col-md-3 col-lg-3 col-sm-6 col-xs-12 pb-3 low-opacity"
            : "col-md-3 col-lg-3 col-sm-6 col-xs-12 pb-3"
        }
        key={`first_account_stat_${index}`}
      >
        <div
          class={`card iq-mb-2 card-disable border-success header-risk-card`}
        >
          <div class="card-body text-primary card-body-small">
            <h6 class="card-title risk-card-title mb-1">{title}</h6>
            <h2 class={`card-number ${scoreClass} risk-card-score`}>{score}</h2>
          </div>
          {stepCompleted ? (
            <img className="card-icon" src={checkCircle} alt={title} />
          ) : (
            <img src={halfCircleLoader} className="card-loading-icon" />
          )}
        </div>
      </div>
    </>
  );
};

export default FirstAccountStatsSteps;
