import { useState } from "react";
import "./style.scss";
import Ticketogo from "../../assets/img/icons/header/ticket.svg";
import { useSelector } from "react-redux";
import { LOADER_CONFIG } from "../../utils/Constants";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import NoDataContainer from "../cards/no-data";
import Pagination from "../pagination/Pagination";
import CreateIssueModal from "../modal/issue-modal/CreateIssue";

const Index = ({ tablePadding = "px-3" }) => {
  const [btn, setBtn] = useState(1);

  const tickets = useSelector((state) => state.ticket.tickets);

  const page_ = sessionStorage.getItem("page");
  const [page, setPage] = useState(page_ ? parseInt(page_) : 1);
  const [sortType, setSortType] = useState("desc");
  const [sortField, setSortField] = useState("cdDatastoreRiskScore");
  const [loading, setLoading] = useState(false);
  const [createIssueModal, setCreateIssueModal] = useState(false);

  const limit = 10;

  const hasMore = (offset, count) => {
    if (offset >= count) {
      return false;
    } else {
      return true;
    }
  };

  const makeSearch = (ev) => {
    ev.preventDefault();
  };

  const [totalIssuesCount, setTotalIssuesCount] = useState(tickets.length - 1);

  const previousPage = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const sortData = (field) => {
    if (field === sortField && sortType === "asc") {
      setSortType("desc");
    } else if (field === sortField && sortType === "desc") {
      setSortType("asc");
    } else {
      setSortType("desc");
    }

    setSortField(field);
    setPage(1);
  };

  return (
    <>
      <div className="tickets">
        <div className="row align-items-center">
          <div className="col-12 col-md-12 col-xl-4 mb-2 page-heading">
            TICKET OVERVIEW
          </div>
        </div>
        <BlockUi
          tag="div"
          blocking={loading}
          loader={
            <Loader
              active
              type={LOADER_CONFIG.type}
              color={LOADER_CONFIG.color}
            />
          }
        >
          <div id="datastores">
            <div className="table-main">
              <div class="row">
                <div className="col-12 col-md-12 col-xl-4 page-heading">
                  TICKETS - 0 | FILTERED TO - 0
                </div>

                <div class="col col-xl-8 align-self-end">
                  <div class="p-2 text-right">
                    <Pagination
                      page={page}
                      setPage={(val) => setPage(val)}
                      limit={limit}
                      totalCount={0}
                    />
                  </div>
                </div>
              </div>

              <button className="btn btn-primary mr-2 ml-3">
                OPEN TICKETS - 0
              </button>
              <button className="btn btn-primary">EXCEPTIONS - 4</button>

              <div className="row">
                <div className="col col-xl-12">
                  <div className="table-responsive">
                    <table
                      className={`mb-0 pb-3 table table-border-less table-hover ${tablePadding}`}
                    >
                      <thead>
                        <tr className="vuls-row">
                          <th scope="col"></th>
                          <th scope="col">Name</th>
                          <th scope="col">Created On</th>
                          <th scope="col">Created By</th>
                          <th scope="col">Assigned To</th>
                          <th scope="col">Description</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <img src={Ticketogo} />
                          </td>
                          <td>-</td>
                          <td>-</td>

                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>
                            <i class="ri-search-line"></i>
                            <i
                              class="ri-focus-2-line"
                              onClick={() => setCreateIssueModal(true)}
                            ></i>
                            <i class="ri-delete-bin-2-fill"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BlockUi>

        {createIssueModal ? (
          <CreateIssueModal handleModal={() => setCreateIssueModal(false)} />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Index;
