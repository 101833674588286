import "./style.scss";

const Tabs = ({ data, setSelectedTab, accessTabClicked, selectedTab }) => (
  <div className="tabs">
    <ul>
      {data.map((d, index) => (
        <li>
          {d.title === "ACCESS - 0" ? (
            <a class={"custom-nav-link"}>{d.title}</a>
          ) : (
            <a
              class={
                (index === 0 && selectedTab === "attack-path") ||
                (index === 1 && selectedTab === "access")
                  ? `custom-nav-link active`
                  : "custom-nav-link"
              }
              onClick={
                index === 0
                  ? () => setSelectedTab("attack-path")
                  : () => accessTabClicked()
              }
              data-toggle="tab"
              href="#home"
            >
              {d.title}
            </a>
          )}
        </li>
      ))}
    </ul>
  </div>
);
export default Tabs;
