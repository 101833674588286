import "./Card.scss";

const Card = ({ title, custom = "", children, subTitleComp = null }) => {
  return (
    <div id={custom ? `card-layout ${custom}` : "card-layout"}>
      <div>
        <h5 className="text-white title mb-2">
          {title} {subTitleComp ? subTitleComp : null}
          {title === "Achievements" ? (
            <span className="sub-title-right">30 Days</span>
          ) : (
            ""
          )}
        </h5>
        <div className="">{children}</div>
      </div>
    </div>
  );
};

export default Card;
