import Bar from "./bar";
import "./css/sheet.scss";
import LivenessIcon from "../../../assets/img/icons/liveness-icon.svg";
import LockIcon from "../../../assets/img/icons/lock-icon.svg";
import GenericDB from "../../../components/charts/data-store/images/Generic_DB.svg";
import "../Home.scss";
import Bug from "../../../components/charts/data-store/images/bug.svg";
import { datastoreIcons } from "../../../utils/styles";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { LOADER_CONFIG } from "../../../utils/Constants";
import AttackIcon from "../../../assets/img/icons/attack-icon.svg";
import HackerIcon from "../../../assets/img/icons/hacker-icon.svg";
import { useHistory } from "react-router-dom";
import { isJsonEmpty } from "../../../utils/utils";
import { useEffect } from "react";

const DataStores = ({
  vunerability,
  risktype,
  setSelectedBar,
  selectedBar,
  setIsBarClicked,
  page,
  datastore,
  datastores,
  tab,
  filter,
  loading,
  setSelectedFilter,
  firstAccountStatus,
}) => {
  const history = useHistory();

  const handleBar = (bar) => {
    const element = document.getElementById("bottom-panel");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    if (tab === 1) {
      if (selectedBar.datastoreId === bar.datastoreId) {
        // setSelectedBar({});
        return;
      } else {
        sessionStorage.setItem("selected_bar", JSON.stringify(bar));
        setSelectedBar(bar);
        setIsBarClicked(true);
      }
    } else {
      if (selectedBar.securityIssueInstanceId === bar.securityIssueInstanceId) {
        // setSelectedBar({});
        return;
      } else {
        sessionStorage.setItem("selected_bar", JSON.stringify(bar));
        setSelectedBar(bar);
        setIsBarClicked(true);
      }
    }
  };

  const hasMore = (offset, count) => {
    if (offset >= count) {
      return false;
    } else {
      return true;
    }
  };

  const viewAllDatastores = () => {
    if (firstAccountStatus?.first_acct_risk_complete) {
      history.push("/datastore");
    }
  };

  const getUpdatedDatastores = () => {
    return (
      <div className="data-store">
        <div>
          <div className="content data-store-section">
            <div className="info-top-panel mb-2 mt-3">
              <div className={`empty-column `}></div>
              {!isJsonEmpty(datastores) &&
                datastores &&
                datastores?.map((bar, index) => (
                  <div
                    className={
                      "colum-no-background d-flex align-items-center justify-content-center flex-wrap "
                    }
                    key={index}
                  >
                    {/* {bar?.attackStatus && (
                      <img
                        src={HackerIcon}
                        width="18px"
                        className="hacker-icon "
                        title="Direct Anomaly"
                      />
                    )} */}
                    {/* {bar?.indirectAttackStatus && (
                      <img
                        src={AttackIcon}
                        width="18px"
                        className="hacker-icon"
                        title="Indirect Anomaly"
                      />
                    )} */}
                  </div>
                ))}
            </div>
            <div className="info">
              <div
                className={
                  datastores && datastores.length <= 0
                    ? `no-data-main-colum ${
                        tab === 1
                          ? "datastores-main-colum"
                          : "issues-main-colum"
                      }`
                    : `main-colum ${
                        tab === 1
                          ? "datastores-main-colum"
                          : "issues-main-colum"
                      }`
                }
              >
                <div className="head">
                  {vunerability ? (
                    <span>
                      <img src={Bug} className="bug-img" />
                    </span>
                  ) : (
                    <h3>Store Type</h3>
                  )}
                </div>
                <div className="body">
                  <div
                    className={tab === 1 ? "title-1" : "title-1 issues-title-1"}
                  >
                    <h3>
                      {vunerability ? "impacted datastores" : "Key Issues"}
                    </h3>
                  </div>

                  <div
                    className={`title-2 ${tab === 1 ? "" : "issues-title-2"}`}
                  >
                    <h3> Key Attack Paths</h3>
                  </div>
                </div>
              </div>
              {!isJsonEmpty(datastores) &&
                datastores &&
                datastores.map((bar, index) => (
                  <>
                    <Bar
                      datastore={datastore}
                      impactedDatastoreList={bar.impactedDatastoreList}
                      bar={bar}
                      selectedBar={selectedBar}
                      type={bar.vulnerabilityID ? bar.displayId : bar.type}
                      logo={
                        datastoreIcons[bar.type]
                          ? datastoreIcons[bar.type]
                          : bar.vulnerabilityID
                          ? Bug
                          : GenericDB
                      }
                      key={index}
                      numAttackPath={bar.numAttackPath}
                      numSecurityIssues={
                        tab === 1
                          ? bar.numSecurityIssues
                          : bar.impactedDatastoresCount
                      }
                      score={
                        tab === 1
                          ? bar.cdDatastoreRiskScore
                          : bar.cdIssueRiskScore
                      }
                      attack={bar.attack}
                      index={index}
                      vunerability={vunerability}
                      handleBar={handleBar}
                      risktype={risktype}
                      rank={bar.rank}
                      impacts={bar.impacts}
                      card_type={bar.risk_type}
                      bottom={bar.bottom}
                      tab={tab}
                      page={page}
                      firstAccountStatus={firstAccountStatus}
                    />
                  </>
                ))}
            </div>
            <div className="info-bottom-panel ">
              <div className={`empty-column `}></div>
              {!isJsonEmpty(datastores) &&
                datastores &&
                datastores.map((bar, index) => (
                  <div
                    className={
                      "colum-no-background d-flex align-items-center justify-content-center flex-wrap "
                    }
                    key={index}
                  >
                    <span>
                      {/* {bar?.livenessInfo && (
                        <img
                          src={LivenessIcon}
                          className="liveness-icon"
                          width="18px"
                          title="Live Traffic"
                        />
                      )} */}
                      {bar?.sensitiveScore >= 80 && (
                        <img
                          src={LockIcon}
                          width="18px"
                          title="Highly Sensitive"
                        />
                      )}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <BlockUi
      tag="div"
      id="datastores-layout"
      blocking={loading}
      loader={
        <Loader active type={LOADER_CONFIG.type} color={LOADER_CONFIG.color} />
      }
    >
      {/* <ul
        className="nav nav-pills d-flex   mb-1"
        id="datastore-tab"
        role="tablist"
      >
        <li className="nav-item" onClick={() => setSelectedFilter("high-risk")}>
          <a
            className="nav-link active"
            id="high-risk-tab"
            data-toggle="pill"
            href="#high-risk"
            role="tab"
            aria-selected="false"
          >
            Risk
          </a>
        </li>
        <li
          className="nav-item"
          onClick={() => setSelectedFilter("non-compliant")}
        >
          <a
            className="nav-link"
            id="non-compliant-risk-tab"
            data-toggle="pill"
            href="#non-compliant-risk"
            role="tab"
            aria-controls="non-compliant-risk"
            aria-selected="false"
          >
            Policy Violation
          </a>
        </li>{" "}
        <li
          className="nav-item"
          onClick={() => setSelectedFilter("active-anomaly")}
        >
          <a
            className="nav-link"
            id="active-anomaly-tab-risk"
            data-toggle="pill"
            href="#active-anomaly-risk"
            role="tab"
            aria-controls="active-anomaly-risk"
            aria-selected="false"
          >
            Cloud Anomaly
          </a>
        </li>
        <div
          className={`d-flex ml-auto align-items-center view-all ${
            firstAccountStatus?.first_acct_risk_complete
              ? "cursor-pointer"
              : "cursor-default"
          }`}
          onClick={() => viewAllDatastores()}
        >
          See all >{" "}
        </div>
      </ul> */}
      {filter === "all" ? (
        <div className="d-flex mt-4">
          <div className="sub-title">Data Stores</div>
          <div
            className={`d-flex ml-auto align-items-center view-all ${
              firstAccountStatus?.first_acct_risk_complete
                ? "cursor-pointer"
                : "cursor-default"
            }`}
            onClick={() => viewAllDatastores()}
          >
            See all >{" "}
          </div>
        </div>
      ) : (
        <div className="sub-title mt-4">Compliance Violation</div>
      )}
      <div className="tab-content" id="datastore-tabContent">
        <div
          className="tab-pane fade show active"
          id="high-risk"
          role="tabpanel"
          aria-labelledby="high-risk-tab"
        >
          {getUpdatedDatastores()}
        </div>
        <div
          className="tab-pane fade"
          id="non-compliant-risk"
          role="tabpanel"
          aria-labelledby="non-compliant-risk-tab"
        >
          {getUpdatedDatastores()}
        </div>
        <div
          className="tab-pane fade"
          id="active-anomaly-risk"
          role="tabpanel"
          aria-labelledby="active-anomaly-risk-tab"
        >
          {getUpdatedDatastores()}
        </div>
      </div>
    </BlockUi>
  );
};

export default DataStores;
