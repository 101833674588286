import React, { useEffect, useState } from "react";
import "./style.scss";
import { ReactComponent as RDSIcon } from "./icons/rds.svg";
import { ReactComponent as SizeIcon } from "./icons/size.svg";
import { ReactComponent as RadioIcon } from "./icons/radio.svg";
import { ReactComponent as ArrowIcon } from "./icons/arrow.svg";
import { ReactComponent as ArrowDownIcon } from "./icons/arrow-down.svg";

import { ReactComponent as S3Icon } from "./icons/s3.svg";
import { ReactComponent as ElasticCacheIcon } from "./icons/elastic-cache.svg";

import { getDataStoresStatsApi } from "../../../apis/index.js";
import { getCustomerId } from "../../../utils/SessionHelper";
import { datastoreIcons } from "../../../utils/styles";

const DataOverview = ({ selectedAccount }) => {
  const [data, setData] = useState([]);
  const [maxSize, setMaxSize] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const getDatastoreStats = async () => {
    let payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
    };

    let res = await getDataStoresStatsApi(payload);
    if (!res.datastores) return;
    const types = res.datastores.types;
    setData(types);

    let count = 0;
    let maxSize = 0;
    let totalSize = 0;
    let totalCount = 0;

    for (let i of types) {
      count += 1;
      maxSize =
        maxSize < parseInt(i.size.split(" ")[0])
          ? parseInt(i.size.split(" ")[0])
          : maxSize;

      totalSize += parseInt(i.size.split(" ")[0]);
      totalCount += parseInt(i.count);
    }
    setMaxSize(maxSize);
    setTotalSize(totalSize);
    setTotalCount(totalCount);
  };

  useEffect(() => {
    getDatastoreStats();
  }, [selectedAccount]);

  return (
    <div className="overview-data-container">
      <h6> Data Overview</h6>
      <div className="sensitive-table">
        <h2 className="text-white">{totalCount}</h2>
        <hr />
        <div className="row mb-3">
          {data.map((d, index) => (
            <div className="col-sm-6" key={index}>
              <img
                className="icon"
                src={
                  datastoreIcons[d.datastoreType]
                    ? datastoreIcons[d.datastoreType]
                    : datastoreIcons["generic"]
                }
              />
              <span className="num">{d.count}</span>
              <span className="title">{d.datastoreType}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DataOverview;
